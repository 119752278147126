import React, { useEffect, useState } from "react";
import styled from "styled-components";
import RequestEngine from "../../core/RequestEngine";
import {Memory} from "../../core/Memory";

const RanksContainer = styled.div`
    background-color: #000;
    min-height: 100vh;
    padding: 20px;
    color: white;
`;

// Grid layout for rank cards
const RankGrid = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 20px;
    background: #0F211C;
    border-radius: 20px;
    padding: 20px;
`;

// Card style for each rank
const RankCard = styled.div`
    background-color: #0f211c;
    border-radius: 12px;
    padding-top: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    box-shadow: 0 8px 15px rgba(0, 0, 0, 0.2);
    position: relative;
    overflow: hidden;
    border: 1px solid #1c2926;
    transition: transform 0.3s;

    &:hover {
        transform: scale(1.05);
    }
`;

// Status label (e.g., Passed, Current, Pending)
const StatusLabel = styled.div`
    position: absolute;
    top: 10px;
    left: 10px;
    background-color: ${(props) =>
            props.status === "Passed" ? "#1a3c31" : props.status === "Current" ? "#214B72" : "#3c1a1a"};
    color: ${(props) =>
            props.status === "Passed" ? "#21AD58" : props.status === "Current" ? "#21AADF" : "#AD2121"};
    font-size: 12px;
    font-weight: bold;
    padding: 5px 10px;
    border-radius: 5px;
    text-transform: uppercase;
`;

// Rank image
const RankImage = styled.img`
    width: 120px;
    height: 120px;
    object-fit: contain;
    margin-bottom: 20px;
    border: 1px solid #2a3a35;
    border-radius: 50%;
    background-color: #1c2926;
`;

// Rank title and description section
const RankDetails = styled.div`
    text-align: center;
    width: 100%;
    background-color: #000000;
    padding: 10px;
    border-top: 2px solid #fff;
`;

const RankTitle = styled.h3`
    font-size: 22px;
    font-weight: bold;
    color: #e6e6e6;
    margin: 10px 0 5px;
    text-align: left;
    padding: 10px;
`;

const Footer = styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;
    font-size: 14px;
    color: #cccccc;
    padding: 10px;
    background: #000;
`;

const FooterItem = styled.span`
    font-weight: bold;
    color: white;
    padding: 10px;
`;

const RanksScreen = () => {
    const [ranks, setRanks] = useState([]); // State to store the rank data
    const [loading, setLoading] = useState(true); // Loading state


    const [currentorder, setCurrentorder] = useState(0);
    useEffect(() => {
        const fetchData = async () => {
            const user = JSON.parse(Memory.getItem("userinfo"));
            debugger
            setCurrentorder(user?.rank?.orderx || 0)
        };
        fetchData();
    }, []);
    useEffect(() => {
        const fetchRanks = async () => {
            let engine = new RequestEngine();
            try {
                const response = await engine.getItem("user/ranks");
                if (response && response.status === 200 && response.data) {
                    setRanks(response.data.data); // Store the fetched rank data
                } else {
                    console.error("Failed to fetch ranks:", response.data.message);
                }
            } catch (error) {
                console.error("An error occurred while fetching ranks:", error);
            } finally {
                setLoading(false); // Stop the loading spinner
            }
        };

        fetchRanks(); // Call the function to fetch rank data
    }, []);

    if (loading) {
        return (
            <RanksContainer>
                <h2>Loading Ranks...</h2>
            </RanksContainer>
        );
    }

    return (
        <RanksContainer>
            <h2 id="title">Ranks</h2>
            <RankGrid>
                {ranks.map((rank, index) => {
                    // Determine the status based on rank.orderx
                    let status = "Pending";
                    if (rank.orderx < currentorder) {
                        status = "Passed";
                    } else if (rank.orderx === currentorder) {
                        status = "Current";
                    }

                    return (
                        <RankCard key={index}>
                            <StatusLabel status={status}>{status}</StatusLabel>
                            <RankImage src={rank.fullpicture} alt={rank.title} />
                            <RankDetails>
                                <RankTitle>{rank.title}</RankTitle>
                            </RankDetails>
                            <Footer>
                                <FooterItem>{rank.date}</FooterItem>
                                <FooterItem>{rank.credit} Credit</FooterItem>
                            </Footer>
                        </RankCard>
                    );
                })}
            </RankGrid>
        </RanksContainer>
    );
};

export default RanksScreen;
