import React, { useState, useEffect, useRef } from "react";
import styled, { keyframes, css } from "styled-components";
import top1 from "../../../assets/images/hand.webp";

// Define animations
const fadeIn = keyframes`
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
`;

const slideInLeft = keyframes`
    from {
        transform: translateX(-50%);
        opacity: 0;
    }
    to {
        transform: translateX(0);
        opacity: 1;
    }
`;

const slideInRight = keyframes`
    from {
        transform: translateX(50%);
        opacity: 0;
    }
    to {
        transform: translateX(0);
        opacity: 1;
    }
`;

const scaleUp = keyframes`
    from {
        transform: scale(0.8);
        opacity: 0;
    }
    to {
        transform: scale(1);
        opacity: 1;
    }
`;

// Styled Components
const Content = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 50px;
    padding: 0 20px;
    width: 80%;
    margin: 0 auto;
    padding-top: 50px;
    padding-bottom: 50px;
    gap: 20px;

    ${({ isVisible }) =>
            isVisible &&
            css`
                animation: ${fadeIn} 1s ease-out;
            `}

    @media (max-width: 768px) {
    flex-direction: column;
    width: 90%;
    gap: 30px;
}
`;

const Section = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;

    @media (max-width: 768px) {
        width: 100%;
    }
`;

const TextSection = styled(Section)`
    align-items: flex-start;
    text-align: left;
    max-width: 569px;
    width: 100%;

    ${({ isVisible }) =>
            isVisible &&
            css`
                animation: ${slideInLeft} 1s ease-out;
            `}

    @media (max-width: 768px) {
    align-items: center;
    text-align: center;
}
`;

const Title = styled.h1`
    color: #fff;
    font-size: 48px;
    line-height: 1.2;
    margin-bottom: 20px;

    @media (max-width: 768px) {
        font-size: 36px;
    }

    @media (max-width: 480px) {
        font-size: 28px;
    }
`;

const Subtitle = styled.p`
    font-size: 18px;
    margin-bottom: 10px;
    font-weight: 400;
    line-height: 25.2px;
    color: #b3b3b3;

    @media (max-width: 768px) {
        font-size: 16px;
    }

    @media (max-width: 480px) {
        font-size: 14px;
    }
`;

const ImageSection = styled(Section)`
    ${({ isVisible }) =>
            isVisible &&
            css`
                animation: ${slideInRight} 1s ease-out;
                img {
                    animation: ${scaleUp} 1s ease-out;
                }
            `}

    img {
        max-width: 100%;
        height: auto;
        width: 500px;

        @media (max-width: 768px) {
            width: 350px;
        }

        @media (max-width: 480px) {
            width: 250px;
        }
    }
`;

// Component
const Section3 = () => {
    const [isVisible, setIsVisible] = useState(false);
    const sectionRef = useRef(null);

    useEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => {
                if (entry.isIntersecting) {
                    setIsVisible(true);
                }
            },
            {
                threshold: 0.3, // Trigger animation when 30% visible
            }
        );

        if (sectionRef.current) {
            observer.observe(sectionRef.current);
        }

        return () => {
            if (sectionRef.current) {
                observer.unobserve(sectionRef.current);
            }
        };
    }, []);

    return (
        <Content ref={sectionRef} isVisible={isVisible} id="partner">
            <TextSection isVisible={isVisible}>
                <Title>Become an Official Partner</Title>
                <Subtitle>
                    • Final Step : “After reaching top ranks and building a strong
                    network, become an official partner with Multyncome, enjoying
                    exclusive rewards and benefits.”
                </Subtitle>
                <Subtitle>
                    • Rewards : Special recognition, extra profits, luxury awards, and an
                    influential role within the Multyncome community.
                </Subtitle>
            </TextSection>

            <ImageSection isVisible={isVisible}>
                <img src={top1} alt="Multyncome" />
            </ImageSection>
        </Content>
    );
};

export default Section3;
