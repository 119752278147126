import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { FaSort, FaSortUp, FaSortDown } from "react-icons/fa";
import RequestEngine from "../../core/RequestEngine";
import { Utilites } from "../../core/Utilites";
import UserSideMenu from "./UserSideMenu";
import tree from "../../assets/images/tree.svg";
import add from "../../assets/images/add.svg";
import sand from "../../assets/images/sand.svg";
import {Memory} from "../../core/Memory";


const getRandomColor = () => {
    const colors = ["#f9d84a", "#4caf50", "#2196f3", "#ff5722", "#9c27b0"];
    return colors[Math.floor(Math.random() * colors.length)];
};




// Table container

const PartnersContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 80vh;
  padding: 20px;
  color: white;

  @media (min-width: 768px) {
    flex-direction: row;
    gap: 20px;
  }
`;

const GenealogySection = styled.div`
  background-color: #0f211c;
  padding: 15px;
  border-radius: 10px;
  margin: 20px 0;
  color: #d1d1d1;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.3);

  @media (min-width: 768px) {
    margin: 20px;
    padding: 20px;
  }
`;

const GenealogyHeader = styled.h3`
  color: #21ad58;
  margin-bottom: 15px;
  font-size: 18px;
`;

const GenealogyItem = styled.div`
  background-color: #2c2c2c;
  padding: 10px;
  border-radius: 5px;
  margin-bottom: 8px;
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: background-color 0.3s;

  &:hover {
    background-color: #3d3d3d;
  }
`;

const ProfileImage = styled.div`
  width: 30px;
  height: 30px;
  background-color: ${getRandomColor};
  color: #2e3a59;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  font-size: 16px;
  margin-right: 10px;

  @media (max-width: 768px) {
    width: 25px;
    height: 25px;
    font-size: 14px;
  }
`;

const ProfileName = styled.span`
  color: #21ad58;
  margin-left: 10px;

  @media (max-width: 768px) {
    font-size: 14px;
  }
`;

const StatsSection = styled.div`
  background-color: #0f211c;
  padding: 15px;
  border-radius: 10px;
  margin: 20px 0;
  color: #d1d1d1;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.3);

  @media (min-width: 768px) {
    margin: 20px;
    padding: 20px;
  }
`;

const ViewProfileButton = styled.button`
  background-color: #21ad58;
  color: #0d0d0d;
  padding: 8px 12px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 10px;
  font-weight: bold;
  transition: background-color 0.3s;

  &:hover {
    background-color: #28cc80;
  }

  @media (max-width: 768px) {
    padding: 6px 10px;
    font-size: 14px;
  }
`;

const TableContainer = styled.div`
  width: 100%;
  background-color: #0f211c;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.3);
  overflow-x: auto;

  @media (max-width: 768px) {
    padding: 15px;
  }
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  font-size: 14px;
  background: #00160f;

  @media (max-width: 768px) {
    font-size: 12px;
  }
`;

const TableRow = styled.tr`
  cursor: pointer;

  &:nth-child(even) {
    border-top: 1px solid #0f211c;
    border-bottom: 1px solid #0f211c;
  }

  &:hover {
    background-color: #333;
  }
`;

const TableHeader = styled.th`
  padding: 8px;
  text-align: left;
  color: #fff;
  font-weight: bold;
  cursor: pointer;

  &:hover {
    color: #21ad58;
  }

  @media (max-width: 768px) {
    padding: 6px;
    font-size: 12px;
  }
`;

const TableCell = styled.td`
  padding: 8px;
  color: #d3d3d3;
  font-size: 12px;

  @media (max-width: 768px) {
    font-size: 10px;
    padding: 6px;
  }
`;


const StatusSpan = styled.span`
    font-size: 12px;
    font-style: italic;
    color: #888;
`;


const SortIcon = styled.span`
  margin-left: 5px;
  color: #21AD58;
`;


// Container for the whole screen





const ToggleSwitch = styled.input`
    appearance: none;
    width: 50px;
    height: 24px;
    background-color: ${({ checked }) => (checked ? "#21AD58" : "#555")};
    border-radius: 24px;
    cursor: pointer;
    position: relative;
    outline: none;
    transition: background-color 0.3s;

    &:before {
        content: "";
        width: 20px;
        height: 20px;
        background-color: white;
        border-radius: 50%;
        position: absolute;
        top: 2px;
        left: ${({ checked }) => (checked ? "26px" : "2px")};
        transition: left 0.3s;
    }
`;





const NumberSpan = styled.span`
    background-color: #333;
    color: #f1f1f1;
    border-radius: 50%;
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
`;




const StatHeader = styled.h3`
    color: #21AD58;
    margin-bottom: 10px;
`;



const StatItem = styled.div`
    display: flex;
    justify-content: space-between;
    margin-bottom: 8px;
    color: #b5b5b5;
    font-weight: 600;
    font-size: 14px;
`;

// Table container

const HeaderRow = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    flex-wrap: wrap;

    @media (min-width: 768px) {
        flex-wrap: nowrap;
    }
`;





const SpanCell = styled.span`
    border: 1px solid ${({ isMember }) => (isMember ? "#DEB000" : "#17743D")};
    color: ${({ isMember }) => (isMember ? "#DEB000" : "#17743D")};
    padding: 10px;
    border-radius: 10px;
    background-color: ${({ isMember }) => (isMember ? "rgba(255, 215, 0, 0.1)" : "rgba(23, 116, 61, 0.1)")};
`;



const ContactIcon = styled.div`
    color: #21AD58;
    cursor: pointer;
    margin-left: auto;

    &:hover {
        color: #21ad58;
    }
`;

const Partners = () => {
    const [genealogy, setGenealogy] = useState(false);
    const [partners, setPartners] = useState([]);
    const [currentId, setCurrentId] = useState("");
    const [genealogylist, setGenealogylist] = useState([]);

    const [parentIds, setParentIds] = useState([]); // State to store the accumulated parent IDs

    const [userinfo, setUserinfo] = useState({});
    const [sortConfig, setSortConfig] = useState({ key: "totalOV", direction: "ascending" });
    const [includeMember, setIncludeMember] = useState(false);
    const [selectedUser, setSelectedUser] = useState(null);

    const fetchPartnersData = async () => {
        const engine = new RequestEngine();
        const response = await engine.getItem("user/referral?deep=" + currentId+"&parent="+parentIds.join(','));
        if (response && response.status === 200 && response.data.success) {
            setPartners(response.data.data.data);
            setUserinfo(response.data.data.userinfo);
            setGenealogylist(response.data.data.genealogylist)
        } else {
            Utilites.showErrorMessage("Failed to fetch partners data.");
        }
    };


    useEffect(() => {
        // Check if "sub" is present in the URL on component mount
        const urlParams = new URLSearchParams(window.location.search);
        setGenealogy(urlParams.has('sub'));
    }, [window.location.search]);

    useEffect(() => {
        // Fetch data whenever parentIds changes
        if (parentIds.length > 0) {
            fetchPartnersData();
        }
    }, [parentIds]);
    useEffect(() => {

        const fetchData = async () => {
            const user = JSON.parse(Memory.getItem("userinfo"));
            setParentIds([user.id]); // Update parentIds with user ID
            setParentIds((prevParentIds) => {
                const newParentIds = [user.id];
                return newParentIds;
            });
            console.log("Setting parentIds:", [user.id]);
        };
        fetchData();

    }, []);

    const filteredPartners = [...partners]
        .filter(partner => !includeMember || partner.ismember)
        .sort((a, b) => {
            if (a[sortConfig.key] < b[sortConfig.key]) {
                return sortConfig.direction === "ascending" ? -1 : 1;
            }
            if (a[sortConfig.key] > b[sortConfig.key]) {
                return sortConfig.direction === "ascending" ? 1 : -1;
            }
            return 0;
        });

    const handleSort = (key) => {
        let direction = "ascending";
        if (sortConfig.key === key && sortConfig.direction === "ascending") {
            direction = "descending";
        }
        setSortConfig({ key, direction });
    };

    const getSortIcon = (key) => {
        if (sortConfig.key === key) {
            return sortConfig.direction === "ascending" ? <FaSortUp /> : <FaSortDown />;
        }
        return <FaSort />;
    };

    const handleRowClick = (partner) => {
        setSelectedUser(partner);
    };

    const closeUserSideMenu = () => {
        setSelectedUser(null);
    };

    const handleGenealogyItemClick = (index, userId) => {
        if(index<genealogylist.length-1){
            setCurrentId(userId);
            const newParentIds = parentIds.slice(0, index + 1);
            setParentIds(newParentIds);

            // Update URL parameters
            const url = new URL(window.location);
            url.searchParams.set('sub', userId);
            url.searchParams.set('parent', newParentIds.join(','));
            window.history.pushState({}, '', url);
        }

    };


    const handleContactIconClick = (partner) => {
        setCurrentId(partner._id)
        // Update parentIds with the new partner ID
        const newParentIds = [...parentIds, partner._id];
        setParentIds(newParentIds);

        // Update the URL with 'sub' and 'parent' parameters
        const url = new URL(window.location);
        url.searchParams.set('sub', partner._id);
        url.searchParams.set('parent', newParentIds.join(','));
        window.history.pushState({}, '', url); // Update the URL without reloading the page


    };

    return (
        <PartnersContainer>
            {genealogy && (
                <div>
                    <GenealogySection>
                        <GenealogyHeader>Genealogy</GenealogyHeader>
                        {genealogylist.map((user, index) => (
                            <GenealogyItem
                                key={index}
                                onClick={() => handleGenealogyItemClick(index, user._id)}
                            >
                                <NumberSpan>{index + 1}</NumberSpan>
                                <ProfileName>{user.first_name} {user.last_name} {index<genealogylist.length-1  && <img style={{marginLeft: 4}} src={add} alt="add" width={15}/>}</ProfileName>
                            </GenealogyItem>
                        ))}
                    </GenealogySection>
                    <StatsSection>
                        <StatHeader>{userinfo?.username}'s Stats</StatHeader>
                        <ViewProfileButton onClick={() => setSelectedUser(userinfo)}>
                            View User Profile
                        </ViewProfileButton>
                        <StatItem>
                            <span>Credit:</span>
                            <span>{userinfo?.credit}</span>
                        </StatItem>
                        <h4 style={{ marginTop: "20px", color: "#999" }}>MEMBERS</h4>
                        <StatItem>
                            <span>IS MEMBER:</span>
                            <span>{userinfo?.ismember ? "YES" : "NO"}</span>
                        </StatItem>
                        <StatItem>
                            <span>Referrals:</span>
                            <span>{partners.length}</span>
                        </StatItem>
                    </StatsSection>
                </div>
            )}
            <div style={{ flex: 1 }}>
                <HeaderRow>
                    <h2 id="title">Referral</h2>
                </HeaderRow>
                <TableContainer>
                    <h1>Referral User</h1>
                    <Table>
                        <thead>
                        <TableRow>
                            <TableHeader onClick={() => handleSort("first_name")}>
                                Name {getSortIcon("first_name")}
                            </TableHeader>
                            <TableHeader onClick={() => handleSort("rank")}>
                                Type {getSortIcon("ismember")}
                            </TableHeader>
                            <TableHeader onClick={() => handleSort("newPartners")}>
                                New Partners {getSortIcon("newPartners")}
                            </TableHeader>
                            <TableHeader onClick={() => handleSort("credit")}>
                                PC {getSortIcon("credit")}
                            </TableHeader>
                            <TableHeader onClick={() => handleSort("dc")}>
                                DC {getSortIcon("dc")}
                            </TableHeader>
                            <TableHeader onClick={() => handleSort("tcreditmonthly")}>
                                Total Credit(M) {getSortIcon("tcreditmonthly")}
                            </TableHeader>
                            <TableHeader onClick={() => handleSort("tcredit")}>
                                Total Credit(Y) {getSortIcon("tcredit")}
                            </TableHeader>


                            <TableHeader></TableHeader>
                        </TableRow>
                        </thead>
                        <tbody>
                        {filteredPartners.map((partner, index) => (
                            <TableRow key={index} onClick={() => handleRowClick(partner)}>
                                <TableCell>
                                    <div style={{display: "flex"}}>
                                        <ProfileImage>{partner?.first_name?.charAt(0)}</ProfileImage>

                                        <div style={{display:"block"}}>
                                            <div>{partner.first_name}&nbsp;<b>{partner.last_name}</b></div>
                                            <div> <StatusSpan>{!partner.secretcode ? "Active" : "Inactive"}</StatusSpan>

                                                <StatusSpan>
                                                    {partner.ismember && partner.remainingDays &&
                                                        <div style={{display: "flex", color: "#21AD58"}}>
                                                            <div>({partner.remainingDays}) days</div>
                                                            <img src={sand} alt="sand"
                                                                 width={15}/></div>}
                                                </StatusSpan></div>

                                        </div>

                                    </div>

                                </TableCell>
                                <TableCell> <SpanCell isMember={partner.ismember}>{partner.ismember ? "Member" : "Not a Member"}</SpanCell></TableCell>
                                <TableCell>{partner.contactsmember}/{partner.contacts}</TableCell>
                                <TableCell>{partner.credit}</TableCell>
                                <TableCell>{partner.dc}</TableCell>
                                <TableCell>{partner.tcreditmonthly}</TableCell>
                                <TableCell>{partner.tcredit}</TableCell>
                                <TableCell>
                                    {partner.contacts > 0 && (
                                        <ContactIcon onClick={(e) => {
                                            e.stopPropagation();
                                            handleContactIconClick(partner);
                                        }}>
                                            <img src={tree} alt="tree" width={25}/>
                                        </ContactIcon>
                                    )}
                                </TableCell>
                            </TableRow>
                        ))}
                        </tbody>
                    </Table>
                </TableContainer>

                {selectedUser && <UserSideMenu user={selectedUser} onClose={closeUserSideMenu} />}
            </div>
        </PartnersContainer>
    );
};

export default Partners;
