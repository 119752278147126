import React from "react";
import styled from "styled-components";
import { Link, useNavigate } from "react-router-dom"; // Import useNavigate for navigation
import logo from "../../assets/images/logo.svg";

const Bar = styled.div`
    border-bottom: 1px solid #2a3a35;
    width: 100%;
`;

const Header = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 30px 20px;
    background: #00160f;
    margin: 0 auto;
    width: 80%;

    @media (max-width: 768px) {
        width: 94%;
        padding: 20px;
    }
`;

const Logo = styled.div`
    display: flex;
    align-items: center;

    img {
        width: 150px;
        height: auto;

        @media (max-width: 768px) {
            width: 120px;
        }
    }
`;

const Nav = styled.div`
    display: flex;
    gap: 20px;

    @media (max-width: 768px) {
        display: none; /* Hide navigation items on mobile */
    }
`;

const NavItem = styled.a`
    text-decoration: none;
    color: #ffffff;
    font-size: 16px;

    &:hover {
        text-decoration: underline;
    }
`;

const JoinButton = styled.button`
    background-color: #21ad58;
    color: #fff;
    border: none;
    border-radius: 5px;
    padding: 10px 25px;
    font-size: 14px;
    cursor: pointer;

    &:hover {
        opacity: 0.8;
    }

    @media (max-width: 768px) {
        font-size: 13px;
        padding: 8px 20px;
    }
`;

// Main Component
const Menu = () => {
    const navigate = useNavigate(); // Initialize the navigation function

    const handleJoinClick = () => {
        navigate("/login"); // Navigate to the /login route
    };

    return (
        <div>
            <Header>
                <Logo>
                    <Link to={"/"}>
                        <img src={logo} alt="Multyncome" />
                    </Link>
                </Logo>
                <Nav>
                    <Link to={"/"}>
                        <NavItem>Home</NavItem>
                    </Link>
                    <NavItem href="#rewards">Rewards</NavItem>
                    <NavItem href="#trade">AI Trade</NavItem>
                    <NavItem href="#affiliate">Affiliate</NavItem>
                    <NavItem href="#partner">Partner</NavItem>
                </Nav>
                <JoinButton onClick={handleJoinClick}>Join Now</JoinButton>
            </Header>
            <Bar />
        </div>
    );
};

export default Menu;
