import React, { useState, useEffect, useRef } from "react";
import styled, { keyframes, css } from "styled-components";
import top1 from "../../../assets/images/top4.png";

// Animations
const fadeInLeft = keyframes`
    from {
        opacity: 0;
        transform: translateX(-50%);
    }
    to {
        opacity: 1;
        transform: translateX(0);
    }
`;

const fadeInRight = keyframes`
    from {
        opacity: 0;
        transform: translateX(50%);
    }
    to {
        opacity: 1;
        transform: translateX(0);
    }
`;

const Content = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 50px;
    padding: 0 20px;
    width: 80%;
    margin: 0 auto;
    gap: 20px;
    opacity: 0;

    ${({ isVisible }) =>
            isVisible &&
            css`
                opacity: 1;
                transition: opacity 0.5s ease-out;
            `}

    @media (max-width: 768px) {
    flex-direction: column;
    width: 90%;
    gap: 30px;
}
`;

const Section = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;

    @media (max-width: 768px) {
        width: 100%;
    }
`;

const TextSection = styled(Section)`
    align-items: flex-start;
    text-align: left;

    ${({ isVisible }) =>
            isVisible &&
            css`
                animation: ${fadeInLeft} 1s ease-out forwards;
            `}

    @media (max-width: 768px) {
    align-items: center;
    text-align: center;
}
`;

const Title = styled.h1`
    color: #fff;
    font-size: 48px;
    line-height: 1.2;
    margin-bottom: 20px;

    @media (max-width: 768px) {
        font-size: 36px;
    }

    @media (max-width: 480px) {
        font-size: 28px;
    }
`;

const Subtitle = styled.p`
    font-size: 16px;
    margin-bottom: 10px;
    font-weight: 400;
    color: #b3b3b3;
    line-height: 25.2px;

    @media (max-width: 768px) {
        font-size: 14px;
    }

    @media (max-width: 480px) {
        font-size: 12px;
    }
`;

const ImageSection = styled(Section)`
    ${({ isVisible }) =>
            isVisible &&
            css`
                animation: ${fadeInRight} 1s ease-out forwards;
            `}

    img {
        max-width: 100%;
        height: auto;
        width: 500px;

        @media (max-width: 768px) {
            width: 350px;
        }

        @media (max-width: 480px) {
            width: 250px;
        }
    }
`;

const Section4 = () => {
    const [isVisible, setIsVisible] = useState(false);
    const sectionRef = useRef(null);

    useEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => {
                if (entry.isIntersecting) {
                    setIsVisible(true);
                }
            },
            { threshold: 0.3 } // Trigger when 30% of the section is visible
        );

        if (sectionRef.current) {
            observer.observe(sectionRef.current);
        }

        return () => {
            if (sectionRef.current) {
                observer.unobserve(sectionRef.current);
            }
        };
    }, []);

    return (
        <Content ref={sectionRef} isVisible={isVisible}>
            <TextSection isVisible={isVisible}>
                <Title>Investment and Wealth Mindset Secrets with AI</Title>
                <Subtitle>
                    • Engaging Headline: “Invest Smartly with Multyncome: Wealth Secrets
                    and AI”
                </Subtitle>
                <Subtitle>
                    • Description: “Give yourself the chance to achieve sustainable and
                    easy income with advanced AI solutions. Start today by developing a
                    wealth mindset and leverage our technology to make your life easier
                    and better.”
                </Subtitle>
            </TextSection>

            <ImageSection isVisible={isVisible}>
                <img src={top1} alt="Multyncome" />
            </ImageSection>
        </Content>
    );
};

export default Section4;
