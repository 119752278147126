import React, { useEffect, useRef, useState } from "react";
import styled, { keyframes, css } from "styled-components";
import top2 from "../../../assets/images/top2.png";
import top3 from "../../../assets/images/top3.png";

// Keyframe animation for text fade-in and slide-up
const fadeInUp = keyframes`
    from {
        opacity: 0;
        transform: translateY(20px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
`;

// Keyframe animation for image fade-in and scale-up effect
const fadeInScale = keyframes`
    from {
        opacity: 0;
        transform: scale(0.95);
    }
    to {
        opacity: 1;
        transform: scale(1);
    }
`;

const BoxedContent = styled.div`
    background-color: #0d221c;
    width: 100%;
    position: relative;
`;

const Content = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 20px;
    margin: 0 auto;
    width: 80%;
    gap: 20px;

    ${({ isVisible }) =>
            isVisible &&
            css`
                animation: ${fadeInUp} 1s ease-out;
            `}

    @media (max-width: 768px) {
    flex-direction: column;
    width: 90%;
}
`;

const Section = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;

    @media (max-width: 768px) {
        width: 100%;
    }
`;

const TextSection = styled(Section)`
    align-items: flex-start;
    text-align: left;

    @media (max-width: 768px) {
        align-items: center;
        text-align: center;
    }
`;

const Title = styled.h1`
    color: #fff;
    font-size: 56px;
    line-height: 1.2;
    margin-bottom: 20px;

    ${({ isVisible }) =>
            isVisible &&
            css`
                animation: ${fadeInUp} 1s ease-out;
            `}

    @media (max-width: 768px) {
    font-size: 36px;
}

    @media (max-width: 480px) {
        font-size: 28px;
    }
`;

const Subtitle = styled.p`
    font-size: 18px;
    margin-bottom: 10px;
    font-weight: 400;
    color: #b3b3b3;
    line-height: 25.2px;

    ${({ isVisible }) =>
            isVisible &&
            css`
                animation: ${fadeInUp} 1s ease-out;
            `}

    @media (max-width: 768px) {
    font-size: 16px;
}

    @media (max-width: 480px) {
        font-size: 14px;
    }
`;

const ImageSection = styled(Section)`
    img {
        max-width: 100%;
        height: auto;
        width: 350px;

        ${({ isVisible }) =>
                isVisible &&
                css`
                    animation: ${fadeInScale} 1s ease-out;
                `}

        @media (max-width: 768px) {
        width: 250px;
    }

        @media (max-width: 480px) {
            width: 200px;
        }
    }
`;

const Cursor = styled.div`
    position: fixed;
    pointer-events: none;
    transform: translate(-50%, -50%);
    transition: all 0.1s ease;
    z-index: 9999;

    width: 0;
    height: 0;
    border-radius: 50%;

    box-shadow: 10px 10px 40px rgba(50, 205, 50, 1),
    10px 10px 100px 40px rgba(50, 205, 50, 1);
`;

const Section2 = () => {
    const [isVisible, setIsVisible] = useState(false);
    const sectionRef = useRef(null);
    const cursorRef = useRef(null);

    useEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => {
                if (entry.isIntersecting) {
                    setIsVisible(true);
                }
            },
            { threshold: 0.5 }
        );

        if (sectionRef.current) {
            observer.observe(sectionRef.current);
        }

        return () => {
            if (sectionRef.current) {
                observer.unobserve(sectionRef.current);
            }
        };
    }, []);

    useEffect(() => {
        const updateCursorPosition = (e) => {
            if (cursorRef.current) {
                cursorRef.current.style.top = `${e.clientY}px`;
                cursorRef.current.style.left = `${e.clientX}px`;
            }
        };

        window.addEventListener("mousemove", updateCursorPosition);

        return () => {
            window.removeEventListener("mousemove", updateCursorPosition);
        };
    }, []);

    return (
        <div id={"affiliate"} ref={sectionRef}>
            <Cursor ref={cursorRef} />
            <BoxedContent>
                <Content isVisible={isVisible}>
                    <ImageSection isVisible={isVisible}>
                        <img src={top2} alt="Multyncome" />
                    </ImageSection>
                    <TextSection isVisible={isVisible}>
                        <Title isVisible={isVisible}>Progress to Affiliate</Title>
                        <Subtitle isVisible={isVisible}>
                            • Step 2 : “Once you’re comfortable with the system, join the
                            <br />
                            affiliate program and share opportunities with others.”
                        </Subtitle>
                        <Subtitle isVisible={isVisible}>
                            • Benefit: Earn additional commissions and build a network that
                            <br />
                            boosts your income.
                        </Subtitle>
                    </TextSection>
                </Content>
                <Content isVisible={isVisible}>
                    <TextSection isVisible={isVisible}>
                        <Title isVisible={isVisible}>
                            Achieve Higher Ranks and a Notable Monthly Income
                        </Title>
                        <Subtitle isVisible={isVisible}>
                            • Step 3 : “Continue growing within the platform, achieving higher
                            ranks through consistent activity and successful referrals.”
                        </Subtitle>
                        <Subtitle isVisible={isVisible}>
                            • Benefit: Each higher rank offers additional rewards and a steady
                            monthly income, enhancing your earnings.
                        </Subtitle>
                    </TextSection>
                    <ImageSection isVisible={isVisible}>
                        <img src={top3} alt="Multyncome" />
                    </ImageSection>
                </Content>
            </BoxedContent>
        </div>
    );
};

export default Section2;
