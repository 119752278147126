import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useParams } from 'react-router-dom';
import RequestEngine from "../../core/RequestEngine";
import { Utilites } from "../../core/Utilites";
import CoursePlayer from "./CoursePlayer";
import Constants from "../../core/Constants";

// Container for the Course Details
const CourseDetailsContainer = styled.div`
    background-color: #00160f;
    min-height: 100vh;
    padding: 20px;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
`;

const CourseCard = styled.div`
    background-color: #0f211c;
    border-radius: 12px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.3);
    display: flex;
    flex-direction: column;
    padding: 30px;
    gap: 20px;
    max-width: 1100px;
    width: 100%;

    @media (min-width: 768px) {
        flex-direction: row;
        padding: 40px;
    }
`;

const CourseDetailsContent = styled.div`
    flex: 2;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    color: white;
    gap: 20px;

    @media (max-width: 768px) {
        text-align: center;
    }
`;

// Course Header
const CourseHeader = styled.div`
    h1 {
        font-size: 28px;
        color: #21AD58;
        margin: 0;

        @media (max-width: 768px) {
            font-size: 24px;
        }
    }

    p {
        font-size: 16px;
        color: #aaa;
        margin: 10px 0;

        @media (max-width: 768px) {
            font-size: 14px;
        }
    }
`;

// Course Player Section
const PlayerContainer = styled.div`
    width: 100%;
    border-radius: 8px;
    overflow: hidden;

    iframe {
        width: 100%;
        height: 300px;
        border: none;

        @media (min-width: 768px) {
            height: 400px;
        }
    }
`;

const CourseDetails = () => {
    const { id } = useParams();
    const [course, setCourse] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchCourseDetails = async () => {
            let engine = new RequestEngine();
            try {
                const response = await engine.getItem(`user/course/details/${id}`);
                if (response && response.status === 200 && response.data) {
                    setCourse(response.data.data);
                } else {
                    Utilites.showErrorMessage(
                        response.data.message
                    );
                }
            } catch (error) {
                console.error("An error occurred while fetching course details:", error);
            } finally {
                setLoading(false);
            }
        };

        fetchCourseDetails();
    }, [id]);

    if (loading) {
        return (
            <CourseDetailsContainer>
                <h2>Loading Course Details...</h2>
            </CourseDetailsContainer>
        );
    }

    if (!course) {
        return (
            <CourseDetailsContainer>
                <h2>Course not found</h2>
            </CourseDetailsContainer>
        );
    }

    return (
        <CourseDetailsContainer>
            <CourseCard>
                <CourseDetailsContent>
                    <CourseHeader>
                        <h1 style={{textAlign:"center"}}>{course.title}</h1>
                    </CourseHeader>
                    <PlayerContainer>
                        <CoursePlayer url={Constants.serverlink + course.url} />
                    </PlayerContainer>
                </CourseDetailsContent>
            </CourseCard>
        </CourseDetailsContainer>
    );
};

export default CourseDetails;
