import React, {useEffect, useRef, useState} from "react";
import styled from "styled-components";
import { FaPlay, FaPause, FaVolumeUp, FaExpand, FaVolumeMute } from "react-icons/fa";
import RequestEngine from "../../core/RequestEngine";

import usa from "../../assets/images/usa.png";
import saudi from "../../assets/images/saudi.png";
import landing from "../landing/Landing";
import {Utilites} from "../../core/Utilites";
import store from "../../core/redux/store";
import {addVideo, setVideos} from "../../core/redux/videoActions";
import {useDispatch, useSelector} from "react-redux";

// Styled Components
const VideoPlayerContainer = styled.div`
    position: relative;
    background-color: #000000;
    border-radius: 15px;
    overflow: hidden;
    width: 100%;
    width: 800px;
    margin: 20px auto;
`;


const VideoElement = styled.video`
    width: 100%;
    height: auto;
`;

const Controls = styled.div`
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    color: white;
`;

const ControlButton = styled.button`
    background: none;
    border: none;
    color: white;
    font-size: 20px;
    cursor: pointer;
    display: flex;
    align-items: center;

    &:hover {
        color: #21AD58;
    }
`;

const ProgressContainer = styled.div`
    flex: 1;
    margin: 0 10px;
    display: flex;
    align-items: center;
`;

const ProgressBar = styled.input`
    width: 100%;
    height: 8px;
    cursor: pointer;
    background: transparent;
    -webkit-appearance: none;

    &::-webkit-slider-runnable-track {
        background: #3b3d3b;
        height: 5px;
        border-radius: 3px;
    }

    &::-webkit-slider-thumb {
        -webkit-appearance: none;
        width: 12px;
        height: 12px;
        background: #21AD58;
        border-radius: 50%;
        cursor: pointer;
        margin-top: -4px;
    }
`;

const FlagContainer = styled.div`
    position: absolute; /* Position it relative to the video container */
    top: 10px; /* Place it near the top */
    right: 10px; /* Align it to the right */
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    z-index: 10; /* Ensure it appears above the video */
`;

const TitleContainer = styled.h3`
    position: absolute; /* Position it relative to the video container */
    top: 10px; /* Place it near the top */
    left: 10px; /* Align it to the left */
    color: white;
    text-align: ${(props) => (props.isEnglish ? "left" : "right")};
    margin: 0; /* Remove default margin */
    z-index: 10; /* Ensure it appears above the video */
`;
const FlagButton = styled.button`
    border: ${(props) => (!props.isSelected ? "5px solid #21AD58" : "1px solid #21AD58")};
    border-radius: 50%;
    padding: 5px;
    background-color: #0F211C;
    cursor: pointer;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: ${(props) => (!props.isSelected ? "0 0 5px #21AD58" : "none")};
    transition: all 0.3s ease;

    &:hover {
        box-shadow: 0 0 5px #21ad58;
    }

    img {
        width: 30px;
        height: auto;
        border-radius: 50%;
    }
`;

const TimeDisplay = styled.span`
    font-size: 14px;
    color: #bbb;
    min-width: 60px;
    text-align: center;
`;

const VolumeBar = styled.input`
    width: 100px;
    height: 8px;
    cursor: pointer;
    background: transparent;
    -webkit-appearance: none;

    &::-webkit-slider-runnable-track {
        background: #3b3d3b;
        height: 5px;
        border-radius: 3px;
    }

    &::-webkit-slider-thumb {
        -webkit-appearance: none;
        width: 12px;
        height: 12px;
        background: #21AD58;
        border-radius: 50%;
        cursor: pointer;
        margin-top: -4px;
    }
`;

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const ModalContent = styled.div`
  background: #1c1c28;
  padding: 20px;
  border-radius: 10px;
  color: white;
  width: 400px;
  max-width: 90%;
`;

const Question = styled.div`
  margin-bottom: 10px;
  font-size: 16px;
`;

const AnswerButton = styled.button`
  background-color: ${(props) => (props.isSelected ? "#21AD58" : "#333")};
  color: white;
  border: none;
  border-radius: 5px;
  padding: 10px;
  margin: 5px;
  cursor: pointer;

  &:hover {
    background-color: #21ad58;
  }
`;



const NavigationButton = styled.button`
  background-color: ${(props) => (props.disabled ? "#333" : "#21ad58")};
  color: ${(props) => (props.disabled ? "#777" : "#fff")};
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  font-size: 16px;
  cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
  margin: 5px;

  &:hover {
    background-color: ${(props) => (props.disabled ? "#333" : "#21AD58")};
  }
`;

const NavigationButtons = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
`;

const VideoPlayer = () => {
    const videoRef = useRef(null);
    const [loading, setLoading] = useState(true); // Loading state
    const [questions, setQuestions] = useState([]);
    const [title, setTitle] = useState("");
    const [titlear, setTitlear] = useState("");

    const [isPlaying, setIsPlaying] = useState(false);
    const [currentTime, setCurrentTime] = useState(0);
    const [duration, setDuration] = useState(0);
    const [volume, setVolume] = useState(1);
    const [isMuted, setIsMuted] = useState(false);
    const [currentVideoIndex, setCurrentVideoIndex] = useState(0);
    const [videoURL, setVideoURL] = useState("");
    const [videoId, setVideoId] = useState("");
    const [showQuestionsModal, setShowQuestionsModal] = useState(false);
    const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
    const [answers, setAnswers] = useState({});


    const selectedVideoIndex = useSelector((state) => state.videos.selected); // Index of selected video
    const videos = useSelector((state) => state.videos.list); // List of videos

    useEffect(() => {
        if (selectedVideoIndex !== null && videos[selectedVideoIndex]) {
            const selectedVideo = videos[selectedVideoIndex];
            setVideoId(selectedVideo.url);
            setTitle(selectedVideo.title);
            setTitlear(selectedVideo.titlear);

            fixVideoLink(selectedLanguage,selectedVideo.url);
            setLoading(false);
        }
    }, [selectedVideoIndex, videos]);
    // Fetch video URL when the current video index changes
    useEffect(() => {
        const fetchVideoURL = async () => {
            try {
                let engine = new RequestEngine();
                try {
                    const response = await engine.getItem("user/video-start");
                    if (response && response.status === 200 && response.data) {
                        setVideoId(response.data.data.id)
                        setTitle(response.data.data.title)
                        setTitlear(response.data.data.titlear)
                        fixVideoLink(selectedLanguage,response.data.data.id);
                        fillVideos(response.data.data.oldvideos)
                    } else {
                        console.error("Failed to fetch ranks:", response.data.message);
                    }
                } catch (error) {
                    console.error("An error occurred while fetching ranks:", error);
                } finally {
                    setLoading(false); // Stop the loading spinner
                }


            } catch (error) {
                console.error("Error fetching video URL", error);
            }
        };

        fetchVideoURL();
    }, []);


    const fillVideos=(initialVideos)=>{
        // Example video data

        // Dispatch initial videos to Redux
        store.dispatch(setVideos(initialVideos));
    }

    const togglePlayPause = () => {
        if (isPlaying) {
            videoRef?.current.pause();
        } else {
            videoRef?.current.play();
        }
        setIsPlaying(!isPlaying);
    };

    const fixVideoLink = (lang ,externalVideo="") => {
        if (lang=="en") {
            if(externalVideo){
                setVideoURL(externalVideo+"_en.mp4");
            }else{
                setVideoURL(videoId+"_en.mp4");
            }

        } else {
            if(externalVideo){
                setVideoURL(externalVideo+"_ar.mp4");
            }else{
                setVideoURL(videoId+"_ar.mp4");
            }
        }

        setShowQuestionsModal(false); // Hide modal
        setAnswers({}); // Reset answers
        setCurrentQuestionIndex(0); // Reset question index

        // Move to the next video if applicable
        if (videoRef) {
            setIsPlaying(false); // Reset play state
            videoRef?.current?.load(); // Reload video
        }
    };

    const handleTimeUpdate = () => {
        setCurrentTime(videoRef.current.currentTime);
    };

    const handleLoadedMetadata = () => {
        setDuration(videoRef.current.duration);
    };

    const handleProgressChange = (e) => {
        const seekTime = (e.target.value / 100) * duration;
        videoRef.current.currentTime = seekTime;
        setCurrentTime(seekTime);
    };

    const handleVolumeChange = (e) => {
        const newVolume = e.target.value / 100;
        videoRef.current.volume = newVolume;
        setVolume(newVolume);
        setIsMuted(newVolume === 0);
    };

    const toggleMute = () => {
        setIsMuted(!isMuted);
        videoRef.current.volume = isMuted ? volume : 0;
    };

    const toggleFullscreen = () => {
        if (videoRef.current.requestFullscreen) {
            videoRef.current.requestFullscreen();
        }
    };
    const [selectedLanguage, setSelectedLanguage] = useState("en"); // "en" for English, "ar" for Arabic

    const handleLanguageChange = (language) => {
        setSelectedLanguage(language);

            fixVideoLink(language);
    };

    const formatTime = (time) => {
        const minutes = Math.floor(time / 60);
        const seconds = Math.floor(time % 60);
        return `${minutes}:${seconds < 10 ? `0${seconds}` : seconds}`;
    };


    const dispatch = useDispatch();

    const handleAddVideo = (titlear,title,videoid,imageUrl,duration="",createdAt="") => {
        const newVideo = {
            titlear: titlear,
            title: title,
            url: videoid,
            imageUrl:imageUrl,
            duration: duration,
            date: createdAt,
        };

        dispatch(addVideo(newVideo)); // Dispatch the action to add the new video
    };
    const handleVideoEnd = async () => {
        // Call endpoint after video ends
        try {


            let engine = new RequestEngine();
            try {
                const response = await engine.postItem("user/video-end",{
                    videoIndex: currentVideoIndex,
                    videoId,
                });
                if (response && response.status === 200 && response.data) {

                    setVideoId(response.data.data.id)
                    setTitle(response.data.data.title)
                    setTitlear(response.data.data.titlear)
                    fixVideoLink(selectedLanguage,response.data.data.id);
                    if(response.data.data.questions){
                        let updatedqeustions = response.data.data.questions
                        if(selectedLanguage=="en"){
                            updatedqeustions = updatedqeustions.map(item=>({question:item.questionen,options:item.optionsen}))
                        }
                        setQuestions(updatedqeustions); // Assuming the API response includes the video URL
                        setShowQuestionsModal(true); // Show questions modal
                    }else{
                        setShowQuestionsModal(false); // Hide modal
                        setAnswers({}); // Reset answers
                        setCurrentQuestionIndex(0); // Reset question index

                        // Move to the next video if applicable
                        if (currentVideoIndex < questions.length - 1) {
                            setCurrentVideoIndex((prevIndex) => prevIndex + 1);
                            setIsPlaying(false); // Reset play state
                            videoRef.current?.load(); // Reload video
                        }

                        handleAddVideo(response.data.data.titlear,response.data.data.title,response.data.data.id,response.data.data.imageUrl,response.data.data.duration,"Today")
                    }

                } else {
                    console.error("Failed to fetch ranks:", response.data.message);
                }
            } catch (error) {
                console.error("An error occurred while fetching ranks:", error);
            } finally {
                 setLoading(false); // Stop the loading spinner
            }

            console.log("Video end API call successful");
        } catch (error) {
            console.error("Error calling video end API", error);
        }

    };

    const handleAnswerSelect = (questionIndex, answer) => {
        setAnswers((prevAnswers) => ({
            ...prevAnswers,
            [questionIndex]: answer,
        }));
    };

    const handleNextQuestion = async () => {
        if (currentQuestionIndex < questions.length - 1) {
            setCurrentQuestionIndex(currentQuestionIndex + 1);
        } else {
            // Call endpoint with answers after user submits

            let engine = new RequestEngine();
            try {

                const response = await engine.postItem("user/submit-answers",{
                    answers,
                    questions,
                    videoId,
                });
                if (response && response.status === 200 && response.data) {
                    setVideoId(response.data.data.id)
                    setTitle(response.data.data.title)
                    setTitlear(response.data.data.titlear)

                    handleAddVideo(response.data.data.titlear,response.data.data.title,response.data.data.id,response.data.data.imageUrl,response.data.data.duration,"Today")
                    fixVideoLink(selectedLanguage,response.data.data.id);
                } else {
                    console.error("Failed to fetch ranks:", response.data.message);
                }
            } catch (error) {
                console.error("An error occurred while fetching ranks:", error);
            } finally {
                setLoading(false); // Stop the loading spinner
            }

            setShowQuestionsModal(false); // Hide modal
            setAnswers({}); // Reset answers
            setCurrentQuestionIndex(0); // Reset question index

            // Move to the next video if applicable
            if (currentVideoIndex < questions.length - 1) {
                setCurrentVideoIndex((prevIndex) => prevIndex + 1);
                setIsPlaying(false); // Reset play state
                videoRef?.current?.load(); // Reload video
            }
        }
    };

    const handlePreviousQuestion = () => {
        if (currentQuestionIndex > 0) {
            setCurrentQuestionIndex(currentQuestionIndex - 1);
        }
    };

    return (
        <VideoPlayerContainer>
            <div>
                <TitleContainer isEnglish={selectedLanguage === "en"}>
                    {selectedLanguage === "en" ? title : titlear}
                </TitleContainer>
                <FlagContainer>
                    <FlagButton
                        isSelected={selectedLanguage == "en"}
                        onClick={() => handleLanguageChange("ar")}
                    >
                        <img src={saudi} width={20}/>
                    </FlagButton>
                    <FlagButton
                        isSelected={selectedLanguage == "ar"}
                        onClick={() => handleLanguageChange("en")}
                    >
                        <img src={usa} width={20}/>
                    </FlagButton>
                </FlagContainer>
            </div>
            {!loading ? (
                <VideoElement
                    ref={videoRef}
                    src={videoURL}
                    onTimeUpdate={handleTimeUpdate}
                    onLoadedMetadata={handleLoadedMetadata}
                    onEnded={handleVideoEnd}
                    controls={false}
                />
            ) : (
                <div>Loading video...</div>
            )}
            <Controls>
                <ControlButton onClick={togglePlayPause}>
                    {isPlaying ? <FaPause/> : <FaPlay/>}
                </ControlButton>
                <ProgressContainer>
                    <TimeDisplay>{formatTime(currentTime)}</TimeDisplay>
                    <ProgressBar
                        type="range"
                        min="0"
                        max="100"
                        value={(currentTime / duration) * 100 || 0}
                        onChange={handleProgressChange}
                    />
                    <TimeDisplay>{formatTime(duration)}</TimeDisplay>
                </ProgressContainer>
                <ControlButton onClick={toggleMute}>
                    {isMuted ? <FaVolumeMute/> : <FaVolumeUp/>}
                </ControlButton>
                <VolumeBar
                    type="range"
                    min="0"
                    max="100"
                    value={volume * 100}
                    onChange={handleVolumeChange}
                />
                <ControlButton onClick={toggleFullscreen}>
                    <FaExpand/>
                </ControlButton>
            </Controls>

            {showQuestionsModal && (
                <ModalOverlay>
                    <ModalContent>
                        <Question>{questions[currentQuestionIndex].question}</Question>
                        {questions[currentQuestionIndex].options.map((option, index) => (
                            <AnswerButton
                                key={index}
                                isSelected={answers[currentQuestionIndex] === option}
                                onClick={() => handleAnswerSelect(currentQuestionIndex, option)}
                            >
                                {option}
                            </AnswerButton>
                        ))}
                        <NavigationButtons>
                            <NavigationButton
                                onClick={handlePreviousQuestion}
                                disabled={currentQuestionIndex === 0}
                            >
                                Previous
                            </NavigationButton>
                            <NavigationButton
                                onClick={handleNextQuestion}
                            >
                                {currentQuestionIndex === questions.length - 1 ? "Submit" : "Next"}
                            </NavigationButton>
                        </NavigationButtons>
                    </ModalContent>
                </ModalOverlay>
            )}
        </VideoPlayerContainer>
    );
};

export default VideoPlayer;
