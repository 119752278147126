import React, {useEffect, useState} from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import logo from "../../assets/images/logo.svg";
import affiliate from "../../assets/images/menu/affiliate.png";
import missionIcon from "../../assets/images/menu/mission.png";
import invest from "../../assets/images/menu/invest.png";
import courses from "../../assets/images/menu/courses.png";
import rank from "../../assets/images/menu/rank.png";
import referal from "../../assets/images/menu/referal.png";
import logout from "../../assets/images/menu/logout.png";
import settings from "../../assets/images/menu/settings.png";
import bottombanner from "../../assets/images/bottombanner.png";
import {Memory} from "../../core/Memory";
// Sidebar container
const Sidebar = styled.div`
    position: fixed;
    top: 0;
    left: ${({ isOpen }) => (isOpen ? "0" : "-320px")};
    height: 100vh;
    width: 300px;
    background-color: #0F211C;
    margin-left: 20px;
    margin-top: 20px;
    border-radius: 20px;
    color: white;
    transition: 0.3s ease;
    z-index: 1000;
    display: flex;
    flex-direction: column;
    box-shadow: 4px 0 10px rgba(0, 0, 0, 0.5);
`;

const SidebarHeader = styled.div`
    padding: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #162421;
`;

const SidebarLogo = styled.img`
    width: 200px;
    margin: auto;
`;

const SidebarSectionTitle = styled.div`
    font-size: 12px;
    color: #8e9a95;
    margin: 20px 20px 10px 20px;
    text-transform: uppercase;
`;

const SidebarItem = styled(Link)`
    padding: 15px 20px;
    font-size: 16px;
    cursor: pointer;
    display: flex;
    align-items: center;
    transition: background-color 0.2s ease;
    text-decoration: none;
    color: #d8e4e1;

    &:hover {
        background-color: #162421;
        color: #00ffae;
    }

    img {
        margin-right: 10px;
    }
`;

const SidebarFooter = styled.div`
    margin-top: auto;
    padding: 20px;
`;

const SidebarPromo = styled.div`
    padding: 15px;
    border-radius: 10px;
    text-align: center;
    color: white;

    h4 {
        font-size: 14px;
        margin-bottom: 10px;
    }

    button {
        background-color: #00ffae;
        color: #0d1916;
        padding: 10px 15px;
        border: none;
        border-radius: 5px;
        font-size: 14px;
        cursor: pointer;
        transition: 0.3s;

        &:hover {
            background-color: #00d99c;
        }
    }
`;

const SideMenu = ({ isOpen, toggleMenu }) => {

    const [ismember, setIsmember] = useState(false);
    useEffect(() => {
        const fetchData = async () => {
            const user = JSON.parse(Memory.getItem("userinfo"));
            setIsmember(user.ismember);
        };
        fetchData();
    }, []);

    return (
        <Sidebar isOpen={isOpen}>
            <SidebarHeader>
                <SidebarLogo src={logo} alt="Multyncome" />
            </SidebarHeader>
            <SidebarSectionTitle>Menu</SidebarSectionTitle>
            <SidebarItem to="/dashboard" onClick={toggleMenu}>
                <img src={affiliate} width={20}/>
                Home</SidebarItem>
            {ismember &&<SidebarItem to="/affiliate" onClick={toggleMenu}>
                <img src={affiliate} width={20}/>
                Affiliate</SidebarItem>}
            {ismember &&<SidebarItem to="/partners" onClick={toggleMenu}>
                <img src={referal} width={20}/>
                Referral</SidebarItem>}
            <SidebarItem to="/rank" onClick={toggleMenu}>
                <img src={rank} width={20}/>
                Rank</SidebarItem>
            <SidebarItem to="/investment" onClick={toggleMenu}>
                <img src={invest} width={20}/>
                Investment</SidebarItem>
            {ismember &&<SidebarItem to="/report" onClick={toggleMenu}>
                <img src={affiliate} width={20}/>
                Report</SidebarItem>}
            <SidebarItem to="/tracker" onClick={toggleMenu}>
                <img src={courses} width={20}/>
                Histroy Tracker</SidebarItem>
            {ismember &&<SidebarItem to="/mission" onClick={toggleMenu}>
                <img src={missionIcon} width={20}/>
                Mission</SidebarItem>}
            {ismember &&<SidebarItem to="/courses" onClick={toggleMenu}>
                <img src={courses} width={20}/>
                Courses</SidebarItem>}


            <SidebarSectionTitle>Other</SidebarSectionTitle>
            <SidebarItem to="/edit-profile" onClick={toggleMenu}>
                <img src={settings} width={20}/>
                Settings</SidebarItem>
            <SidebarItem  onClick={() => {
                Memory.clear();
                window.location.href = "/login";
            }}>
                <img src={logout} width={20}/>
                Logout</SidebarItem>
            <SidebarFooter>
                <SidebarPromo>
                   <img src={bottombanner} width={200}/>
                </SidebarPromo>
            </SidebarFooter>
        </Sidebar>
    );
};

export default SideMenu;
