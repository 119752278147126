import React, { useState, useEffect } from "react";
import styled from "styled-components";
import {
  FaEye,
  FaEyeSlash,
} from "react-icons/fa";
import { Memory } from "../../core/Memory";
import { Utilites } from "../../core/Utilites";
import RequestEngine from "../../core/RequestEngine";
import { useNavigate } from "react-router-dom"; // Assuming the request engine is already set up
import Fawallet from "../../assets/images/Vector.svg";
import send from "../../assets/images/sendicon.svg";
import request from "../../assets/images/request.svg";
import winner from  "../../assets/images/winner.svg"
import SectionUnderWinner from "./SectionUnderWinner";


const PerformanceSection = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 20px;
  align-items: center;
  flex-wrap: wrap; /* Ensure wrapping for small screens */

  @media (max-width: 1024px) {
    flex-direction: column;
    gap: 15px;
    padding: 20px;
  }

  div {
    font-size: 1.8rem; /* Scales better on smaller screens */
  }
`;

const ButtonSection = styled.div`
  display: flex;
  background: #0f211c;
  border-radius: 30px;
  border: 1px solid #3b3d3b;
  padding: 40px 15px; /* Reduced padding for better fit on small screens */
  align-items: center;
  justify-content: space-evenly;
  flex-grow: 1;
  gap: 20px;

  @media (max-width: 768px) {
    flex-direction: column;
    padding: 20px; /* Adjust padding for small devices */
    width: 175px;
    gap: 34px;
  }
`;

const ButtonIcon = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  color: white;
  font-size: 1rem;
  cursor: pointer;

  .icon {
    width: 50px;
    height: 50px;
    border-radius: 12px;
    background-color: #141414;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 5px;
    font-size: 20px;
    color: #21ad58;
    transition: background-color 0.3s ease, color 0.3s ease;
  }

  &:hover {
    color: #21ad58;

    .icon {
      background-color: #21ad58;
      color: black;
    }
  }

  @media (max-width: 480px) {
    font-size: 0.9rem;

    .icon {
      width: 40px;
      height: 40px;
      font-size: 18px;
    }
  }
`;

const MetricSection = styled.div`
  display: flex;
  flex-grow: 1;
  padding: 30px 15px;
  background: #0f211c;
  height: 100px;
  border-radius: 30px;
  border: 1px solid #3b3d3b;
  align-items: center;
  justify-content: space-around;
  gap: 15px;

  @media (max-width: 768px) {
    flex-direction: column;
    padding: 20px;
    height: auto;
    width: 175px;
    gap: 34px;
  }
`;

const Metric = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  color: white;
  font-size: 0.9rem; /* Scales down on smaller screens */
  text-align: center;

  .title{
    margin-bottom:15px
  }
  .value {
    font-size: 1.2rem; /* Adjust font size */
    font-weight: bold;
    color: ${(props) => props.color || "#21AD58"};
  }

  @media (max-width: 480px) {
    font-size: 0.8rem;

    .value {
      font-size: 1rem;
    }
  }
`;

const WinnerImage = styled.img`
  max-width: 80%; /* Limits width on smaller screens */
  height: auto;

  @media (max-width: 768px) {
    max-width: 100%;
  }
`;

const ModalContent = styled.div`
  background-color: #1c1c28;
  padding: 20px;
  border-radius: 10px;
  width: 400px;
  max-width: 90%;
  color: white;
  text-align: center;

  @media (max-width: 768px) {
    width: 90%;
    padding: 15px;
  }
`;

const Input = styled.input`
  width: 100%;
  padding: 12px;
  background-color: #1e1e1e;
  border: 1px solid ${({ error }) => (error ? "red" : "#333")};
  color: #fff;
  border-radius: 8px;
  font-size: 1rem;

  &:focus {
    outline: none;
    border-color: ${({ error }) => (error ? "red" : "#21AD58")};
    background-color: #333;
  }

  @media (max-width: 480px) {
    font-size: 0.9rem;
  }
`;

const Button = styled.button`
  width: 100%;
  padding: 12px;
  background-color: #21ad58;
  color: #fff;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-size: 1rem;
  margin-top: 15px;
  transition: background-color 0.3s;

  &:hover {
    background-color: #1e8e4f;
  }

  &:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }

  @media (max-width: 480px) {
    font-size: 0.9rem;
  }
`;




const IconWithImage = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const IconImage = styled.img`
  width: 47px;
  height: 47px;
  margin-right: 8px;
`;

const CenteredImageWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;




// Styled components
const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;


const InputWrapper = styled.div`
  position: relative;
  width: 92%;
  margin-bottom: 15px;
`;


const ToggleButton = styled.span`
  position: absolute;
  top: 50%;
  right: 0px;
  transform: translateY(-50%);
  cursor: pointer;
  font-size: 18px;
  color: #21ad58;
`;

const ErrorMessage = styled.div`
  color: red;
  font-size: 12px;
  margin-bottom: 10px;
`;


const ButtonLoader = styled.div`
  border: 3px solid rgba(255, 255, 255, 0.3);
  border-top: 3px solid #fff;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  animation: spin 0.8s linear infinite;

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

const CloseButton = styled(Button)`
  background-color: red;

  &:hover {
    background-color: darkred;
  }
`;

const TopBar = ({topRecruitment,topCoins, accountBalance, coins, topRank,currentCredits }) => {
  const [modalData, setModalData] = useState(null);
  const navigate = useNavigate();
  const [isMfaEnabled, setIsMfaEnabled] = useState(false);
  const [showMfaModal, setShowMfaModal] = useState(false);
  const [mfaValidation, setMfaValidation] = useState(true);
  const [trc, setTrc] = useState("");
  const [otptitle, setOtptitle] = useState("Enter MFA Key");
  const [inputValues, setInputValues] = useState({
    "TRC20 Address": "", // Keep TRC20 Address pre-filled
  });
  const [otp, setOtp] = useState("");
  const [errors, setErrors] = useState({});
  const [transactionData, setTransactionData] = useState({});
  const [passwordVisible, setPasswordVisible] = useState(false); // For password toggle
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    const fetchMfaStatus = async () => {
      const user = JSON.parse(Memory.getItem("userinfo"));
      setTrc(user.trc);
      setInputValues((prevValues) => ({
        ...prevValues,
        "TRC20 Address": user.trc, // Pre-fill TRC20 Address
      }));
      setIsMfaEnabled(user.mfa === true);
    };
    fetchMfaStatus();
  }, []);

  const handleInputChange = (e, field) => {
    let value = e.target.value;
    if (field === "Amount") {
      // Allow only numbers for the Amount field
      value = value.replace(/\D/g, "");
    }

    setInputValues({
      ...inputValues,
      [field]: value,
    });

    if (value) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [field]: false,
      }));
    }
  };

  const handleTopUpClick = () => {
    navigate("/topup");
  };

  const handleSendClick = () => {
    setOtp("");
    setModalData({
      title: "Send",
      fields: ["Username", "Password", "Amount"],
    });
    clearInputsExceptTRC();
  };

  const handleRequestClick = () => {
    setOtp("");
    setModalData({
      title: "withdraw",
      fields: ["Password", "Amount", "TRC20 Address"],
    });
    clearInputsExceptTRC();
  };

  const clearInputsExceptTRC = () => {
    setInputValues((prevValues) => ({
      "TRC20 Address": prevValues["TRC20 Address"], // Keep TRC20 Address intact
      Password: "",
      Amount: "",
      Username: "",
    }));
  };

  const handleSubmit = async () => {
    const requiredFields = modalData.fields;
    const newErrors = {};

    requiredFields.forEach((field) => {
      if (!inputValues[field]) {
        newErrors[field] = true;
      }
    });

    if (
      modalData.title != "Send" &&
      inputValues["Amount"] &&
      parseFloat(inputValues["Amount"]) < 100
    ) {
      newErrors["Amount"] = true;
      Utilites.showErrorMessage("The amount must be at least 100.");
    }
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }

    if (isMfaEnabled) {
      setTransactionData({
        type: modalData.title.toLowerCase(),
        ...inputValues,
      });
      setModalData(null);
      setShowMfaModal(true);
      setOtptitle("Enter MFA Key");
    } else {
      const engine = new RequestEngine();
      engine.getItem("user/generateotp");
      Utilites.showSucessMessage("OTP sent to your email!");
      setTransactionData({
        type: modalData.title.toLowerCase(),
        ...inputValues,
      });
      setModalData(null);
      setShowMfaModal(true);
      setOtptitle("Enter OTP Key");
    }
  };

  const validateOtp = async () => {
    setLoading(true);
    try {
      const data = {
        otp: otp,
        method: "mfa",
        type: transactionData.type,
        username: transactionData.Username || "",
        amount: transactionData.Amount,
        password: transactionData.Password,
        trc: transactionData["TRC20 Address"],
      };

      const engine = new RequestEngine();
      const response = await engine.postItem("user/validateotp", data);

      if (response && response.status === 200 && response.data.success) {
        Utilites.showSucessMessage("Done!");
        setShowMfaModal(false);
      } else {
        Utilites.showErrorMessage(response.data.message);
      }
    } catch (error) {
      Utilites.showErrorMessage("An error occurred during OTP validation.");
    } finally {
      setLoading(false);
    }
  };

  const handleMfaSubmit = () => {
    if (!otp) {
      Utilites.showErrorMessage("Please enter the OTP.");
      return;
    }
    validateOtp();
  };

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  const closeModal = () => {
    setModalData(null);
    setShowMfaModal(false);
  };

  return (
    <div>
      <PerformanceSection>
        <ButtonSection>
          <ButtonIcon onClick={handleTopUpClick}>
            <IconWithImage>
              <IconImage src={Fawallet} alt="Wallet Icon" />
            </IconWithImage>
            <div>Top Up</div>
          </ButtonIcon>
          <ButtonIcon onClick={handleSendClick}>
            <IconWithImage>
              <IconImage src={send} alt="Send Icon" />
            </IconWithImage>
            <div>Send</div>
          </ButtonIcon>
          <ButtonIcon onClick={handleRequestClick}>
            <IconWithImage>
              <IconImage src={request} alt="Request Icon" />
            </IconWithImage>
            <div>Request</div>
          </ButtonIcon>
        </ButtonSection>

        <MetricSection>
          <Metric>
            <div className="title">Credits</div>
            <div className="value">{currentCredits}</div>
          </Metric>
          <Metric>
            <div className="title">Coins</div>
            <div className="value">{coins}</div>
          </Metric>
          <Metric color="#21AD58">
            <div className="title">Balance</div>
            <div className="value">${accountBalance}</div>
          </Metric>
        </MetricSection>
      </PerformanceSection>

      <CenteredImageWrapper>
      <WinnerImage src={winner} alt="winner" />

    </CenteredImageWrapper>
      <SectionUnderWinner topCoins={topCoins}  topRank={topRank} topRecruitment={topRecruitment}  />

      {modalData && (
        <ModalOverlay>
          <ModalContent>
            <h2>{modalData.title}</h2>
            {modalData.fields.map((field, index) => (
              <InputWrapper key={index}>
                <Input
                  type={
                    field === "Password" && !passwordVisible
                      ? "password"
                      : "text"
                  }
                  placeholder={field}
                  error={errors[field]}
                  value={inputValues[field] || ""}
                  onChange={(e) => handleInputChange(e, field)}
                />
                {field === "Password" && (
                  <ToggleButton onClick={togglePasswordVisibility}>
                    {passwordVisible ? <FaEyeSlash /> : <FaEye />}
                  </ToggleButton>
                )}
                {field != "Amount" && errors[field] && (
                  <ErrorMessage>{field} is required</ErrorMessage>
                )}
                {field == "Amount" && errors[field] && (
                  <ErrorMessage>Amount must be minimum 100</ErrorMessage>
                )}
              </InputWrapper>
            ))}
            <Button onClick={handleSubmit}>Submit</Button>
            <CloseButton onClick={closeModal}>Close</CloseButton>
          </ModalContent>
        </ModalOverlay>
      )}

      {showMfaModal && (
        <ModalOverlay>
          <ModalContent>
            <h2>{otptitle}</h2>
            <Input
              type="text"
              placeholder="MFA Key"
              value={otp}
              onChange={(e) => setOtp(e.target.value)}
            />
            <Button onClick={handleMfaSubmit} disabled={loading}>
              {loading ? <ButtonLoader /> : "Verify"}
            </Button>
            <CloseButton onClick={closeModal}>Close</CloseButton>
          </ModalContent>
        </ModalOverlay>
      )}
    </div>
  );
};

export default TopBar;
