import React from "react";
import styled from "styled-components";
import faqImage from "../../../assets/images/faqImage.png"; // Replace with your image path

const BoxContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #fff;
  padding: 50px 20px;
  width: 80%;
  margin: 0 auto;

  @media (max-width: 768px) {
    width: 90%;
    padding: 40px 10px;
  }
`;

const Content = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 50px 20px;
  color: #fff;

  @media (max-width: 768px) {
    flex-direction: column;
    padding: 20px 10px;
  }
`;

const TextSection = styled.div`
  flex: 1.5;
  background-color: #0d221c;
  border-radius: 22px;
  align-items: center;
  padding: 20px;

  @media (max-width: 768px) {
    padding: 15px;
    flex: none;
    width: 100%;
  }
`;

const Title = styled.h1`
  font-size: 36px;
  font-weight: bold;
  margin-bottom: 20px;

  @media (max-width: 768px) {
    font-size: 28px;
    text-align: center;
  }

  @media (max-width: 480px) {
    font-size: 24px;
  }
`;

const Subtitle = styled.p`
  font-size: 18px;
  color: #b3b3b3;
  margin-bottom: 30px;

  @media (max-width: 768px) {
    font-size: 16px;
    text-align: center;
  }

  @media (max-width: 480px) {
    font-size: 14px;
  }
`;

const FAQBox = styled.div`
  background-color: #00150f;
  border-radius: 10px;
  padding: 20px;
  color: #fff;
  margin: 20px;

  @media (max-width: 768px) {
    margin: 15px 0;
  }
`;

const FAQTitle = styled.h2`
  font-size: 22px;
  font-weight: bold;
  color: #00a676;
  margin-bottom: 10px;
  padding-left: 50px;

  @media (max-width: 768px) {
    padding-left: 0;
    text-align: center;
  }
`;

const FAQDescription = styled.p`
  font-size: 16px;
  color: #b3b3b3;
  margin-bottom: 20px;
  padding-left: 50px;

  @media (max-width: 768px) {
    padding-left: 0;
    text-align: center;
  }
`;

const Question = styled.h3`
  font-size: 18px;
  font-weight: bold;
  margin: 20px 0 10px;
  color: #fff;

  @media (max-width: 768px) {
    font-size: 16px;
  }
`;

const Answer = styled.p`
  font-size: 16px;
  line-height: 1.6;
  color: #b3b3b3;

  @media (max-width: 768px) {
    font-size: 14px;
    line-height: 1.4;
  }
`;

const ImageSection = styled.div`
  flex: 1;
  display: flex;
  justify-content: flex-end;

  img {
    max-width: 100%;
    height: auto;
    border-radius: 10px;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.3);

    @media (max-width: 768px) {
      margin-top: 20px;
    }
  }
`;

const FAQ = () => {
  return (
    <BoxContent>
      <Title>Frequently Asked Questions</Title>
      {/* <Subtitle>
        Lorem ipsum dolor sit amet consectetur. Iaculis lectus suspendisse
        semper fringilla tincidunt lobortis facilisis hendrerit.
      </Subtitle> */}
      <Content>
        {/* Text Section */}
        <TextSection>
          <FAQTitle>What Are The Benefits Of Multyncome?</FAQTitle>
          <FAQDescription>
            Online trading’s primary advantages are that it allows you to manage
            your trades at your convenience.
          </FAQDescription>

          <FAQBox>
            <Question>1. What Is Multyncome?</Question>
            <Answer>
              Multyncome is a platform offering AI-driven investment solutions,
              educational resources for online income, and an affiliate program
              to help users build reliable income streams.
            </Answer>

            <Question>2. How Does The AI Trading System Work?</Question>
            <Answer>
              Our AI-powered trading robots analyze markets 24/7, making
              data-driven trading decisions on your behalf. You choose your
              investment level and risk preference, and the AI handles the rest
              to maximize returns.
            </Answer>

            <Question>3. Who Manages The Investment Funds?</Question>
            <Answer>
              Our AI-powered trading robots analyze markets 24/7, making
              data-driven trading decisions on your behalf. You choose your
              investment level and risk preference, and the AI handles the rest
              to maximize returns.
            </Answer>

            <Question>
              4. What Are The Different Investment Plans And Fees?
            </Question>
            <Answer>
              We offer three flexible investment plans with fees based on
              investment amounts:
              <br />
              • $100 - $2,500: 50% Fee on Profits <br />• $2,500 - $5,000: 45%
              Fee on Profits
            </Answer>
          </FAQBox>
        </TextSection>

        {/* Image Section */}
        <ImageSection>
          <img src={faqImage} alt="FAQ Illustration" />
        </ImageSection>
      </Content>
    </BoxContent>
  );
};

export default FAQ;
