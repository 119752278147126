import React from "react";
import styled from "styled-components";
import top1 from "../../../assets/images/top6.webp";
import check from "../../../assets/images/check.svg";
import { Link } from "react-router-dom";

const Content = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 50px;
  padding: 0 20px;
  gap: 20px;
  color: #fff;
  width: 80%;
  margin: 0 auto;
  padding-top: 50px;
  padding-bottom: 50px;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
    text-align: center;
    width: 90%;
  }
`;

const TextSection = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: left;

  @media (max-width: 768px) {
    align-items: center;
    text-align: center;
  }
`;

const Title = styled.h1`
  font-size: 36px;
  font-weight: bold;
  margin-bottom: 20px;

  @media (max-width: 768px) {
    font-size: 30px;
  }

  @media (max-width: 480px) {
    font-size: 24px;
  }
`;

const ListItem = styled.li`
  font-size: 16px;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  color: #b3b3b3;
  line-height: 25.2px;
  width: 80%;

  @media (max-width: 768px) {
    justify-content: center;
    width: 100%;
  }

  @media (max-width: 480px) {
    font-size: 14px;
  }
`;

const CheckIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #22ad58;
  border-radius: 50%;
  width: 40px;
  height: 26px;
  margin-right: 20px;

  img {
    width: 20px;
    height: auto;
  }

  @media (max-width: 768px) {
    width: 30px;
    height: 20px;
    margin-right: 10px;

    img {
      width: 15px;
    }
  }
`;

const Button = styled.button`
  background-color: #22ad58;
  color: #0f1923;
  border: none;
  border-radius: 5px;
  padding: 20px 20px;
  font-size: 16px;
  width: 80%;
  cursor: pointer;
  transition: all 0.3s ease;

  &:hover {
    background-color: #00e6b8;
  }

  @media (max-width: 768px) {
    padding: 15px;
    font-size: 14px;
    width: 100%;
  }
`;

const ImageSection = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    max-width: 100%;
    height: auto;
    width: 500px;
    border-radius: 10px;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.3);

    @media (max-width: 768px) {
      width: 350px;
    }

    @media (max-width: 480px) {
      width: 250px;
    }
  }
`;

const StatsOverlay = styled.div`
  position: absolute;
  top: -50px;
  left: -10px;
  background: #21342ff2;
  color: #fff;
  padding: 10px 35px;
  width: 240px;
  border-radius: 16px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);

  @media (max-width: 768px) {
    width: 200px;
    top: -40px;
    padding: 10px 25px;
  }

  @media (max-width: 480px) {
    width: 160px;
    top: -30px;
    padding: 10px 20px;
  }
`;

const ProgressBar = styled.div`
  background-color: #333;
  border-radius: 5px;
  overflow: hidden;
  margin-top: 10px;
`;

const Progress = styled.div`
  width: 90%;
  background-color: #22ad58;
  height: 10px;

  @media (max-width: 768px) {
    height: 8px;
  }

  @media (max-width: 480px) {
    height: 6px;
  }
`;

const StatsContainer = styled.div`
  position: relative;
  display: inline-block;
`;

const Section6 = () => {
  return (
      <Content id="rewards">
        {/* Text Section */}
        <TextSection>
          <Title>Rewards And Recognition</Title>
          <ListItem>
            <CheckIcon>
              <img src={check} width={15} height={15} />
            </CheckIcon>
            Exclusive Rewards such as luxury cars and special recognitions for top
            investors and dedicated partners
          </ListItem>

          <ListItem>
            <CheckIcon>
              <img src={check} />
            </CheckIcon>
            Recognition and appreciation for those who achieve outstanding
            accomplishments on the platform
          </ListItem>

          <Link to={"/login"}>
            <Button>Start Your Journey Now And Earn Your Rewards</Button>
          </Link>
        </TextSection>

        {/* Image Section */}
        <ImageSection>
          <StatsContainer>
            <img src={top1} alt="Recognition" />
            <StatsOverlay>
              <h1>90%</h1>
              <p>Satisfied Clients</p>
              <ProgressBar>
                <Progress />
              </ProgressBar>
            </StatsOverlay>
          </StatsContainer>
        </ImageSection>
      </Content>
  );
};

export default Section6;
