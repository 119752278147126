import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { FaEye, FaEyeSlash } from "react-icons/fa"; // Importing eye icons for password visibility toggle
import { Memory } from "../../core/Memory";
import { toast } from "react-toastify";
import RequestEngine from "../../core/RequestEngine";
import { Utilites } from "../../core/Utilites";
import { QRCodeSVG } from 'qrcode.react';
import PhoneInput from 'react-phone-input-2'; // Import phone input library
import 'react-phone-input-2/lib/style.css'; // Import phone input styles
import Constants from "../../core/Constants";


const Container = styled.div`
    padding: 20px;
    color: white;
    background-color: #0f161a;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    gap: 20px;
`;

const SectionTitle = styled.h2`
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 15px;
    color: #21AD58;
    text-transform: uppercase;
`;

const Section = styled.div`
    background-color: #0F211C;
    border-radius: 10px;
    padding: 20px;
    margin-bottom: 20px;
`;

const Label = styled.label`
    font-size: 14px;
    font-weight: 500;
    color: #a1b1c3;
    margin-bottom: 5px;
    display: block;
    text-transform: uppercase;
`;

const InputField = styled.input`
    width: 95%;
    padding: 12px 15px;
    margin-bottom: 20px;
    border: 1px solid #2a2f38;
    background-color: #0f1a20;
    border-radius: 8px;
    font-size: 16px;
    color: #e8f0fe;
    transition: border 0.3s;

    &:focus {
        border-color: #21AD58;
        outline: none;
    }
`;

const InputWrapper = styled.div`
    position: relative;
`;

const EyeIcon = styled.div`
    position: absolute;
    top: 50%;
    right: 15px;
    transform: translateY(-50%);
    cursor: pointer;
    color: #21AD58;
    font-size: 18px;
`;

const Dropdown = styled.select`
    width: 100%;
    padding: 12px 15px;
    margin-bottom: 20px;
    border: 1px solid #2a2f38;
    background-color: #0f1a20;
    border-radius: 8px;
    color: #e8f0fe;
    font-size: 16px;
    transition: border 0.3s;

    &:focus {
        border-color: #21AD58;
        outline: none;
    }
`;

const Button = styled.button`
    background-color: #21AD58;
    color: #0f161a;
    padding: 14px;
    border: none;
    border-radius: 8px;
    font-size: 16px;
    font-weight: bold;
    margin: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
    width: 100%;

    &:hover {
        background-color: #21ad58;
    }

    &:disabled {
        background-color: #ccc;
        color: #666;
        cursor: not-allowed;
    }
`;

const ButtonLoader = styled.div`
    border: 3px solid rgba(255, 255, 255, 0.3);
    border-top: 3px solid #21AD58;
    border-radius: 50%;
    width: 24px;
    height: 24px;
    animation: spin 0.8s linear infinite;

    @keyframes spin {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);
        }
    }
`;

const MFAConfigureLink = styled.a`
    color: #21AD58;
    cursor: pointer;
    text-decoration: underline;
    font-size: 14px;

    &:hover {
        color: #21ad58;
    }
`;

const Modal = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
`;

const ModalContent = styled.div`
    background-color: #1a1f26;
    border-radius: 10px;
    padding: 30px;
    max-width: 400px;
    width: 90%;
    color: white;
    text-align: center;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.4);
`;

const Row = styled.div`
    display: flex;
    gap: 20px;
    flex-wrap: wrap;

    @media (max-width: 768px) {
        flex-direction: column;
        gap: 10px; /* Reduce the gap for smaller screens */
    }
`;

const HalfWidthField = styled.div`
    flex: 1; /* Each field takes up equal space */
    min-width: 200px; /* Set a minimum width for smaller screens */
`;
const CloseButton = styled.button`
    background: none;
    border: none;
    color: #21AD58;
    font-size: 18px;
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;

    &:hover {
        color: #21ad58;
    }
`;

const PhoneInputWrapper = styled.div`
    margin-bottom: 20px;

    .react-tel-input .form-control {
        padding: 12px 50px 12px 15px;
        background-color: #0f1a20;
        border: 1px solid #2a2f38;
        color: #e8f0fe;
        font-size: 16px;
        border-radius: 8px;
        transition: border-color 0.3s;
    }

    .react-tel-input .form-control:focus {
        border-color: #21AD58;
        outline: none;
    }

    .react-tel-input .flag-dropdown {
        background-color: #0f1a20;
        border: none;
        border-right: 1px solid #2a2f38;
    }
`;

// Styled components for the Edit Profile Screen



const EditProfileScreen = () => {
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [email, setEmail] = useState("");
    const [lang, setLang] = useState("en");
    const [trc, setTrc] = useState("");
    const [username, setUsername] = useState("");
    const [referral, setReferral] = useState("");
    const [caneditreferral, setCaneditreferral] = useState(false);
    const [nationalId, setNationalId] = useState("");
    const [oldPassword, setOldPassword] = useState("");
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [country, setCountry] = useState("");
    const [isMfaEnabled, setIsMfaEnabled] = useState(false);
    const [countries, setCountries] = useState([]);
    const [obscurePassword, setObscurePassword] = useState(true);
    const [obscureConfirmPassword, setObscureConfirmPassword] = useState(true);
    const [obscureOldPassword, setObscureOldPassword] = useState(true);
    const [phone, setPhone] = useState(""); // Phone number state
    const [loading, setLoading] = useState(false); // Loading state

    const [mfaSecret, setMfaSecret] = useState("");
    const [otpAuthUrl, setOtpAuthUrl] = useState("");
    const [showMFAModal, setShowMFAModal] = useState(false);
    const [showMFAKeyInput, setShowMFAKeyInput] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
            const user = JSON.parse(Memory.getItem("userinfo"));
            setEmail(user.email);
            setFirstName(user.first_name);
            setLastName(user.last_name);
            setUsername(user.username);
            setLang(user.lang)
            setNationalId(user.nationalId);
            setCountry(user.country);
            setReferral(user.referral);
            setCaneditreferral(user.referral.length == 0);
            setTrc(user.trc);
            setIsMfaEnabled(user.mfa === true);
            setCountries(["USA", "Canada", "Germany", "India"]);
            setPhone(user.phone_number); // Set phone number from user data
        };
        fetchData();
    }, []);

    const handleSubmit = async () => {
        if (password !== confirmPassword) {
            toast.error("Passwords do not match");
            return;
        }
        setLoading(true); // Start loading

        const data = {
            password,
            oldPassword,
            mfa: isMfaEnabled,
            lang,
            trc,
            referral,
            phone, // Add phone to the form data
        };

        const engine = new RequestEngine();
        const response = await engine.postItem("user/updateprofile", data);

        if (response && response.status === 200 && response.data.success) {
            const userinfo = response.data.data.user;
            Memory.setItem("userinfo", JSON.stringify(userinfo));
            Utilites.showSucessMessage("Profile Updated!");
        } else {
            Utilites.showErrorMessage(response.data.message);
        }
        setLoading(false);
    };

    const fetchMFAData = async () => {
        const engine = new RequestEngine();
        const response = await engine.getItem("user/enablemfa");
        if (response && response.data) {
            setMfaSecret(response.data.data.base32);
            setOtpAuthUrl(response.data.data.otpauth_url);
            setShowMFAModal(true);
        } else {
            Utilites.showErrorMessage("Failed to load MFA configuration");
        }
    };

    const handleMFAConfigure = () => {
        fetchMFAData();
    };

    const handleNextStep = () => {
        setShowMFAModal(false);
        setShowMFAKeyInput(true);
    };

    const ApplyMFAScreen = () => {
        const [mfaKey, setMfaKey] = useState("");

        const verifyMFAKey = async () => {
            if (!mfaKey) {
                toast.error("Please enter a valid MFA key");
                return;
            }

            const data = { otp: mfaKey };
            const engine = new RequestEngine();
            const response = await engine.postItem("user/verifymfa", data);

            if (response && response.status === 200 && response.data.success) {
                Utilites.showSucessMessage("MFA Enabled!");
                setShowMFAKeyInput(false);
            } else {
                Utilites.showErrorMessage(response.data.message);
            }
        };

        return (
            <Modal>
                <ModalContent>
                    <CloseButton onClick={() => setShowMFAKeyInput(false)}>X</CloseButton>
                    <h3>Apply MFA</h3>
                    <Label>Enter your MFA key</Label>
                    <InputField
                        type="text"
                        value={mfaKey}
                        placeholder="MFA Key"
                        onChange={(e) => setMfaKey(e.target.value)}
                    />
                    <Button onClick={verifyMFAKey}>Verify</Button>
                </ModalContent>
            </Modal>
        );
    };

    return (
        <Container className={"edit"}>
            <h2 id="title">Settings</h2>
            <Section>


                <Row>
                    <HalfWidthField>
                        <Label>Username</Label>
                        <InputField type="text" value={username} readOnly placeholder="Username"/>
                    </HalfWidthField>
                    <HalfWidthField>
                        <Label>Email</Label>
                        <InputField type="email" value={email} readOnly placeholder="Email"/>
                    </HalfWidthField>
                </Row>
                <Row>
                    <HalfWidthField>
                        <Label>First Name</Label>
                        <InputField type="text" value={firstName} readOnly placeholder="First Name"/>
                    </HalfWidthField>
                    <HalfWidthField>
                        <Label>Last Name</Label>
                        <InputField type="text" value={lastName} readOnly placeholder="Last Name"/>
                    </HalfWidthField>
                </Row>

                <Label>Country</Label>
                <Dropdown value={country} disabled>
                    {Constants.countries.map((country) => (
                        <option key={country} value={country}>
                            {country}
                        </option>
                    ))}
                </Dropdown>

                <Label>National ID</Label>
                <InputField type="text" value={nationalId} readOnly placeholder="National ID"/>

                <Label>Referral</Label>
                {!caneditreferral && (
                    <InputField type="text" value={referral} readOnly placeholder="Referral"/>
                )}
                {caneditreferral && (
                    <InputField
                        type="text"
                        value={referral}
                        onChange={(e) => setReferral(e.currentTarget.value)}
                        placeholder="Referral"
                    />
                )}

                <Label>Phone Number</Label>
                <PhoneInput
                    country={'lb'}
                    value={phone}
                    onChange={(phone) => setPhone(phone)}
                    containerStyle={{marginBottom: '10px'}}
                    inputStyle={{
                        padding: "20px 20px 20px 52px",
                        borderRadius: 5,
                        border: "1px solid #333333",
                        fontSize: 16,
                        width: "100%",
                        background: "#14141C",
                        color: "white"
                    }}

                />



                <Label>TRC20 Address</Label>
                <InputField
                    type="trc"
                    value={trc}
                    onChange={(e) => setTrc(e.currentTarget.value)}
                    placeholder="TRC20 Address"
                />
            </Section>

            <SectionTitle>Change Password</SectionTitle>
            <Section>
                <Label>Old Password</Label>
                <InputWrapper>
                    <InputField
                        type={obscureOldPassword ? "password" : "text"}
                        value={oldPassword}
                        placeholder="Password"
                        onChange={(e) => setOldPassword(e.target.value)}
                    />
                    <EyeIcon onClick={() => setObscureOldPassword(!obscureOldPassword)}>
                        {obscureOldPassword ? <FaEyeSlash/> : <FaEye/>}
                    </EyeIcon>
                </InputWrapper>

                <Label>Password</Label>
                <InputWrapper>
                    <InputField
                        type={obscurePassword ? "password" : "text"}
                        value={password}
                        placeholder="Password"
                        onChange={(e) => setPassword(e.target.value)}
                    />
                    <EyeIcon onClick={() => setObscurePassword(!obscurePassword)}>
                        {obscurePassword ? <FaEyeSlash/> : <FaEye/>}
                    </EyeIcon>
                </InputWrapper>

                <Label>Confirm Password</Label>
                <InputWrapper>
                    <InputField
                        type={obscureConfirmPassword ? "password" : "text"}
                        value={confirmPassword}
                        placeholder="Confirm Password"
                        onChange={(e) => setConfirmPassword(e.target.value)}
                    />
                    <EyeIcon onClick={() => setObscureConfirmPassword(!obscureConfirmPassword)}>
                        {obscureConfirmPassword ? <FaEyeSlash/> : <FaEye/>}
                    </EyeIcon>
                </InputWrapper>
            </Section>

            <SectionTitle>Multi-Factor Authentication</SectionTitle>
            <Section>
                <label>
                    <input
                        type="checkbox"
                        checked={isMfaEnabled}
                        onChange={() => setIsMfaEnabled(!isMfaEnabled)}
                    />
                    Enable MFA
                </label>
                {isMfaEnabled && (
                    <MFAConfigureLink onClick={handleMFAConfigure}>
                        Configure MFA
                    </MFAConfigureLink>
                )}
            </Section>

            <Button onClick={handleSubmit} disabled={loading}>
                {loading ? <ButtonLoader/> : "Save Changes"}
            </Button>

            {/* MFA Modal */}
            {showMFAModal && (
                <Modal>
                    <ModalContent>
                        <CloseButton onClick={() => setShowMFAModal(false)}>X</CloseButton>
                        <h3>MFA Setup</h3>
                        <p>Scan the QR code using your authenticator app or use the secret key below.</p>
                        <QRCodeSVG value={otpAuthUrl} size={200}/>
                        <div style={{marginTop: "20px"}}>
                            <Label>MFA Secret</Label>
                            <InputField type="text" value={mfaSecret} readOnly/>
                            <Button
                                onClick={() => {
                                    navigator.clipboard.writeText(mfaSecret);
                                    Utilites.showSucessMessage("Copied!");
                                }}
                            >
                                Copy MFA Secret
                            </Button>
                        </div>
                        <Button onClick={handleNextStep}>Next</Button>
                    </ModalContent>
                </Modal>
            )}

            {/* MFA Key Input Modal */}
            {showMFAKeyInput && <ApplyMFAScreen/>}
        </Container>
    );
};

export default EditProfileScreen;
