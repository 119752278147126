import React from "react";
import styled from "styled-components";
import icon1 from "../../../assets/images/keypoint.svg";
import icon2 from "../../../assets/images/keypoint2.svg";
import icon3 from "../../../assets/images/keypoint3.svg";

// Styled Components
const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #fff;
  padding: 50px 20px;
  width: 80%;
  margin: 0 auto;
`;

const Title = styled.h2`
  font-size: 32px;
  font-weight: bold;
  margin-bottom: 80px;
`;

const KeyPointsWrapper = styled.div`
  display: flex;
  justify-content: center;
  gap: 100px;
  flex-wrap: wrap;
  width: 100%;
  max-width: 1200px;
`;

const Card = styled.div`
  border: 1px solid #22ad58;
  background-color: #0d221c;
  border-radius: 10px;
  padding: 30px 20px;
  text-align: center;
  flex: 1;
  max-width: 400px;
  min-width: 290px;
  height: 240px;
`;

const Icon = styled.div`
  font-size: 50px;
  margin-bottom: 20px;
  color: #00ffcc;
`;

const CardTitle = styled.p`
  font-size: 18px;
  margin: 0;
  color: #fff;
  font-weight: 600;
  line-height: 26.6px;
  text-align: center;
`;

// Main Component
const KeyPoints = () => {
  const points = [
    {
      icon: icon1,
      title: "Access to innovative AI technology",
    },
    {
      icon: icon2,
      title: "Effective and simplified investment strategies",
    },
    {
      icon: icon3,
      title: "Support for your financial growth and achieving your ambitions",
    },
  ];

  return (
    <Content>
      <Title>Key Points:</Title>
      <KeyPointsWrapper>
        {points.map((point, index) => (
          <Card key={index}>
            <Icon>
              <img src={point.icon} alt={`Icon for ${point.title}`} />
            </Icon>

            <CardTitle>{point.title}</CardTitle>
          </Card>
        ))}
      </KeyPointsWrapper>
    </Content>
  );
};

export default KeyPoints;
