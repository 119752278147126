// src/redux/reducers/videoReducer.js
import { SET_VIDEOS, SELECT_VIDEO, ADD_VIDEO } from "./actionTypes";

const initialState = {
    list: [], // List of videos
    selected: null, // Index of the selected video
};

const videoReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_VIDEOS:
            return {
                ...state,
                list: action.payload,
            };
        case SELECT_VIDEO:
            return {
                ...state,
                selected: action.payload,
            };
        case ADD_VIDEO: // Handle the new action
            return {
                ...state,
                list: [...state.list, action.payload], // Add the new video to the list
            };
        default:
            return state;
    }
};

export default videoReducer;
