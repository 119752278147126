import React, { useState, useEffect } from "react";
import styled from "styled-components";
import RequestEngine from "../../core/RequestEngine";
import {Link, useLocation, useNavigate, useParams} from "react-router-dom";

// Main container for the leg child screen
const LegChildContainer = styled.div`
    background-color: #000;
    min-height: 100vh;
    padding: 20px;
    color: white;
`;

// Card style for the leg section and child accounts
const LegCard = styled.div`
    background-color: #1c1c28;
    border-radius: 10px;
    padding: 15px;
    margin-bottom: 20px;
`;

// Header section for the main leg info
const LegHeader = styled.div`
    background-color: #212237;
    border-radius: 10px;
    padding: 20px;
    margin-bottom: 20px;
    border-left: 10px solid red;
`;

const ProgressBar = styled.div`
    background-color: #3b3d3b;
    height: 8px;
    border-radius: 5px;
    margin-top: 10px;
    overflow: hidden;
    position: relative;
`;

const Progress = styled.div`
    background-color: #21AD58;
    height: 100%;
    width: ${(props) => props.width || 0}%;
    transition: width 1s ease-in-out; /* Smooth transition */
`;

const ChildLegCard = styled.div`
    background-color: #f2f2f2;
    border-radius: 10px;
    padding: 10px;
    color: #333;
    margin-bottom: 10px;
`;

const BreadcrumbContainer = styled.div`
    margin-bottom: 20px;
    font-size: 14px;
    color: #bbb;
    a {
        color: #21AD58;
        text-decoration: none;
        &:hover {
            text-decoration: underline;
        }
    }
`;

// For the circles and connectors to represent child hierarchy
const Circle = styled.div`
    width: 15px;
    height: 15px;
    background-color: green;
    border-radius: 50%;
    margin-right: 10px;
    display: inline-block;
`;

const ChildLegRow = styled.div`
    display: flex;
    align-items: center;
    margin-left: 20px;
    font-size: 14px;
`;

// Message to show when no child user is found
const NoChildMessage = styled.p`
    text-align: center;
    font-size: 18px;
    color: #ff5555;
`;

const LegChildScreen = () => {
    const { userId } = useParams(); // Get userId from the URL
    const [childLegs, setChildLegs] = useState([]);
    const location = useLocation();
    const { title, leg, credit, rank } = location.state || {}; // Destructure the passed state
    // Function to fetch leg child data from the API
    const fetchChildData = async (childid) => {
        let engine = new RequestEngine();
        try {
            const response = await engine.getItem(`user/child/${childid}`);
            if (response && response.status === 200 && response.data) {
                const users = response.data.data;
                setChildLegs(users);
            } else {
                console.error("Failed to fetch leg data:", response.data.message);
            }
        } catch (error) {
            console.error("An error occurred while fetching leg data:", error);
        }
    };

    // Fetch leg child data when the component mounts
    useEffect(() => {
        fetchChildData(userId);
    }, [userId]);

    const navigate = useNavigate(); // Initialize the navigate function

    const handleLegClick = (child,childId) => {
        navigate(`/child/${childId}`, {
            state: child,
        });
    };

    return (
        <LegChildContainer>
            <BreadcrumbContainer>
                <Link to="/dashboard">Home</Link> &gt;<Link to="/affiliate">Affiliate</Link>  &gt; {title}
            </BreadcrumbContainer>
            <LegHeader>
                <h3>{title}</h3>
                <div>
                    <p>Credit: {credit}</p>
                    <p>User Rank: {rank}</p>
                </div>
            </LegHeader>
            {/* Check if childLegs is empty and show a message */}
            {childLegs.length === 0 ? (
                <NoChildMessage>No child user found</NoChildMessage>
            ) : (

                childLegs.map((child, index) => (
                    <LegCard key={index} onClick={() => handleLegClick(child,child.id)}>
                        <ChildLegRow>
                            <Circle />
                            <div>
                                {child.title} / {child.rank}
                                <p>{child.credit} credit</p>
                                {child.ismember ? "IS MEMBER" : "NOT A MEMBER"}
                            </div>
                        </ChildLegRow>
                    </LegCard>
                ))
            )}
        </LegChildContainer>
    );
};

export default LegChildScreen;
