import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { FaArrowUp, FaArrowDown } from "react-icons/fa";
import RequestEngine from "../../core/RequestEngine";
import Constants from "../../core/Constants";

// Main container for the activity screen
const ActivityContainer = styled.div`
  background-color: #00160f;
  min-height: 100vh;
  padding: 20px;
  color: white;
  font-family: "Inter", sans-serif;
`;

// Table wrapper with rounded corners and padding
const TableWrapper = styled.div`
    background-color: #0f211c;
    border-radius: 12px;
    padding: 20px;
    margin-top: 20px;
`;

// Styled table with custom headers and row styling
const StyledTable = styled.table`
    width: 100%;
    border-collapse: collapse;
    font-size: 16px;

    th {
        background-color: #00160F;
        color: #21AD58;
        font-weight: bold;
        padding: 12px;
        text-align: left;
        border-bottom: 2px solid #1f392e;
    }

    td {
        padding: 12px;
        border-bottom: 1px solid #1f392e;
        color: #d1d1d1;
        vertical-align: middle;
    }

    td.amount-negative {
        color: #e74c3c;
    }

    td.amount-positive {
        color: #2ecc71;
    }

    tr:hover {
        background-color: #193529;
        transition: background-color 0.2s ease;
    }
`;

// Title for the activity screen
const Title = styled.h2`
    color: white;
    font-weight: bold;
    margin-bottom: 20px;
`;

// Activity screen component
const ReportList = () => {
    const [activityData, setActivityData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [sortConfig, setSortConfig] = useState({ key: null, direction: "ascending" });

    useEffect(() => {
        let mounted = true;
        if (loading) return;

        const fetchData = async () => {
            setLoading(true);
            let engine = new RequestEngine();
            try {
                const response = await engine.getItem("user/userreport");
                debugger
                if (response && response.status === 200 && response.data && mounted) {
                    setActivityData(response.data.data);
                }
            } catch (error) {
                console.error("An error occurred while fetching activity data:", error);
            } finally {
                if (mounted) setLoading(false);
            }
        };

        fetchData();

        return () => {
            mounted = false;
        };
    }, []);

    const sortData = (key) => {
        let direction = "ascending";
        if (sortConfig.key === key && sortConfig.direction === "ascending") {
            direction = "descending";
        }

        const sortedData = [...activityData].sort((a, b) => {
            if (key === "amount") {
                return direction === "ascending" ? a[key] - b[key] : b[key] - a[key];
            } else if (key === "createdAt") {
                return direction === "ascending"
                    ? new Date(a[key]) - new Date(b[key])
                    : new Date(b[key]) - new Date(a[key]);
            } else {
                return direction === "ascending" ? a[key].localeCompare(b[key]) : b[key].localeCompare(a[key]);
            }
        });

        setActivityData(sortedData);
        setSortConfig({ key, direction });
    };

    const getSortIcon = (key) => {
        if (sortConfig.key !== key) return null;
        if (sortConfig.direction === "ascending") return <FaArrowUp />;
        return <FaArrowDown />;
    };

    if (loading) {
        return (
            <ActivityContainer>
                <h2>Loading Report...</h2>
            </ActivityContainer>
        );
    }

    return (
        <ActivityContainer>
            <Title id={"title"}>Report</Title>
            <TableWrapper>
                <StyledTable>
                    <thead>
                    <tr >
                        <th onClick={() => sortData("name")}>
                            Month {getSortIcon("month")}
                        </th>
                        <th onClick={() => sortData("createdAt")}>
                            Download {getSortIcon("link")}
                        </th>
                    </tr>
                    </thead>
                    <tbody>
                    {activityData?.map((item, index) => (
                        <tr key={index}>
                            <td>
                               <span >{item.month}</span>
                            </td>
                            <td> <a
                                href={Constants.serverlink+"upload/reports/"+item.filename}
                                download={item.filename}
                                target="_blank"
                                rel="noopener noreferrer"
                                style={{
                                    display: "inline-block",
                                    backgroundColor: "#21AD58",
                                    color: "white",
                                    textDecoration: "none",
                                    padding: "8px 16px",
                                    borderRadius: "5px",
                                    fontWeight: "bold",
                                    cursor: "pointer",
                                }}
                            >
                                Download
                            </a></td>
                        </tr>
                    ))}
                    </tbody>
                </StyledTable>
            </TableWrapper>
        </ActivityContainer>
    );
};

export default ReportList;
