import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useSelector, useDispatch } from "react-redux";
import { selectVideo } from "../../core/redux/videoActions";
import { Utilites } from "../../core/Utilites";

// Styled Components
const PlaylistContainer = styled.div`
    display: flex;
    flex-direction: column;
    background-color: #011510;
    border-radius: 10px;
    padding: 20px;
    margin: 20px;
    color: white;
    width: 300px;
    overflow-y: auto;
    max-height: 410px;
    margin-bottom: 0;

    @media (max-width: 768px) {
        width: 100%;
    }
`;

const PlaylistTitle = styled.h3`
    font-size: 18px;
    margin-bottom: 20px;
    color: #fff;
`;

const PlaylistItem = styled.div`
    display: flex;
    align-items: center;
    background-color: ${({ selected }) => (selected ? "#333" : "transparent")};
    border-radius: 10px;
    padding: 10px;
    margin-bottom: 10px;
    cursor: pointer;
    transition: background-color 0.3s ease;

    &:hover {
        background-color: #333;
    }
`;

const Thumbnail = styled.div`
    width: 70px;
    height: 50px;
    background-color: #001610;
    border-radius: 5px;
    margin-right: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;

    img {
        width: 70px;
        height: 50px;
        object-fit: cover;
        border-radius: 5px;
    }

    span {
        position: absolute;
        bottom: 5px;
        right: 5px;
        background-color: rgba(0, 0, 0, 0.7);
        color: white;
        font-size: 12px;
        padding: 2px 5px;
        border-radius: 3px;
    }
`;

const VideoDetails = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
`;

const VideoTitle = styled.div`
    font-size: 14px;
    font-weight: bold;
    color: white;
`;

const VideoSubtext = styled.div`
    font-size: 12px;
    color: #bbb;
`;

const ControlsContainer = styled.div`
    display: flex;
    justify-content: space-between;
    margin-top: 0px;
`;

const ControlButton = styled.button`
    background-color: #21ad58;
    color: white;
    border: none;
    padding: 8px 10px;
    font-size: 12px;
    margin: 22px;
    margin-top: 0;
    margin-bottom: 0;
    border-radius: 5px;
    cursor: pointer;

    &:disabled {
        background-color: #444;
        cursor: not-allowed;
    }

    &:hover:enabled {
        background-color: #1e8e4c;
    }
`;

const Playlist = () => {
    const dispatch = useDispatch();
    const videos = useSelector((state) => state.videos.list);
    const selectedVideo = useSelector((state) => state.videos.selected);
    const [thumbnails, setThumbnails] = useState({});

    const handleSelect = (index) => {
        dispatch(selectVideo(index));
    };

    const handleNext = () => {
        if (selectedVideo < videos.length - 1) {
            dispatch(selectVideo(selectedVideo + 1));
        }
    };

    const handlePrevious = () => {
        if (selectedVideo > 0) {
            dispatch(selectVideo(selectedVideo - 1));
        }
    };



    return (
        <div>

            <PlaylistContainer>
                <PlaylistTitle>Play List</PlaylistTitle>
                {videos.map((video, index) => (
                    <PlaylistItem
                        key={index}
                        selected={selectedVideo === index}
                        onClick={() => handleSelect(index)}
                    >
                        <Thumbnail>
                            <img src={video.imageUrl} alt={video.title} />

                            <span>{video.duration}</span>
                        </Thumbnail>
                        <VideoDetails>
                            <VideoTitle>{video.title}</VideoTitle>
                            <VideoSubtext>
                                {Utilites.renderDate(video.date)}
                            </VideoSubtext>
                        </VideoDetails>
                    </PlaylistItem>
                ))}
            </PlaylistContainer>
            <ControlsContainer>
                <ControlButton onClick={handlePrevious} disabled={selectedVideo === 0}>
                    Previous
                </ControlButton>
                <ControlButton onClick={handleNext} disabled={selectedVideo === videos.length - 1}>
                    Next
                </ControlButton>
            </ControlsContainer>
        </div>

    );
};

export default Playlist;
