import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import RequestEngine from "../../core/RequestEngine";
import {Utilites} from "../../core/Utilites";

// Container for the course screen
const CourseContainer = styled.div`
    background-color: #00160f;
    min-height: 100vh;
    padding: 20px;
    color: white;
    font-family: "Inter", sans-serif;
`;

// Search bar and filter container
const SearchContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-bottom: 20px;
`;

const SearchInput = styled.input`
    background-color: #0f211c;
    border: none;
    padding: 12px;
    border-radius: 8px;
    color: white;
    font-size: 16px;
    outline: none;

    &::placeholder {
        color: #555;
    }
`;

const FilterContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
`;

const FilterButton = styled.button`
    background-color: ${({ active }) => (active ? "#21AD58" : "#142a21")};
    color: ${({ active }) => (active ? "#00160f" : "#21AD58")};
    border: none;
    border-radius: 20px;
    padding: 8px 16px;
    font-size: 14px;
    cursor: pointer;
    transition: background-color 0.3s;

    &:hover {
        background-color: #28cc80;
        color: #00160f;
    }
`;

// Rows with white background
const RowContainer = styled.div`
    background-color: #10231D;
    border-radius: 10px;
    padding: 20px;
    margin-bottom: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
`;

// Grid layout for courses (4 per row)
const CourseGrid = styled.div`
    display: grid;
    grid-template-columns: repeat(4, 1fr); /* Four columns */
    gap: 20px;

    @media (max-width: 1024px) {
        grid-template-columns: repeat(2, 1fr); /* Two columns for smaller screens */
    }

    @media (max-width: 768px) {
        grid-template-columns: 1fr; /* Single column for mobile view */
    }
`;

// Course card
const CourseCard = styled.div`
    background-color: #f9f9f9;
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.2s ease, box-shadow 0.2s ease;
    cursor: pointer;
    display: flex;
    flex-direction: column;

    &:hover {
        transform: scale(1.05);
        box-shadow: 0 8px 15px rgba(0, 255, 153, 0.3);
    }
`;

// Course image
const CourseImage = styled.div`
    background-color: white;
    height: 150px;
    display: flex;
    margin-top: 20px;
    justify-content: center;
    align-items: center;

    img {
        max-height: 100%;
        max-width: 100%;
        object-fit: cover;
    }
`;

// Course content
const CourseContent = styled.div`
    padding: 15px;

    h4 {
        margin: 0 0 10px;
        font-size: 16px;
        font-weight: bold;
        color: #333;
    }

    p {
        font-size: 14px;
        color: #666;
        margin: 0;
    }

    .meta-info {
        margin-top: 10px;
        display: flex;
        justify-content: space-between;
        font-size: 14px;
        color: #21AD58;
    }
`;

// CourseScreen Component
const CourseScreen = () => {
    const navigate = useNavigate();
    const [courses, setCourses] = useState([]);
    const [loading, setLoading] = useState(true);
    const [searchQuery, setSearchQuery] = useState("");
    const [activeFilter, setActiveFilter] = useState("");

    // Fetch courses from API
    useEffect(() => {
        const fetchCourses = async () => {
            const engine = new RequestEngine();
            try {
                const response = await engine.getItem("user/course/list");
                debugger
                if (response && response.status === 200 && response.data) {
                    setCourses(response.data.data);
                }else {
                    Utilites.showErrorMessage(
                         response.data.message
                    );
                }
            } catch (error) {
                console.error("An error occurred while fetching courses:", error);
            } finally {
                setLoading(false);
            }
        };

        fetchCourses();
    }, []);

    const handleCourseClick = (id) => {
        navigate(`/courses/${id}`);
    };

    const filteredCourses = courses?.filter((course) => {
        const matchesSearch = course.title.toLowerCase().includes(searchQuery.toLowerCase());
        const matchesFilter = activeFilter ? course.category === activeFilter : true;
        return matchesSearch && matchesFilter;
    });

    if (loading) {
        return (
            <CourseContainer>
                <h2>Loading Courses...</h2>
            </CourseContainer>
        );
    }

    return (
        <CourseContainer>
            <h2 id="title"></h2>
            <br/>
            <h2>Courses</h2>
            <RowContainer>
                <CourseGrid>
                    {filteredCourses.map((course) => (
                        <CourseCard key={course.id} onClick={() => handleCourseClick(course.id)}>
                            <CourseImage>
                                <img src={course.fullpicture} alt={course.title} />
                            </CourseImage>
                            <CourseContent>
                                <h4 style={{textAlign:"center"}}>{course.title}</h4>
                            </CourseContent>
                        </CourseCard>
                    ))}
                </CourseGrid>
            </RowContainer>
        </CourseContainer>
    );
};

export default CourseScreen;
