import React, { useState, useEffect } from 'react';
import styled, { keyframes } from 'styled-components';
import {Link, useNavigate, useParams} from "react-router-dom";
import { Utilites } from "../../core/Utilites";
import RequestEngine from "../../core/RequestEngine";
import logo from "../../assets/images/logo.svg";
import {FaEye, FaEyeSlash} from "react-icons/fa";

// Glowing Effect Animation
const glowingEffect = keyframes`
    0% {
        text-shadow: 0 0 5px #21AD58, 0 0 10px #21AD58, 0 0 20px #21AD58, 0 0 30px #757575;
    }
    50% {
        text-shadow: 0 0 10px #21AD58, 0 0 20px #21AD58, 0 0 30px #21AD58, 0 0 40px #21AD58;
    }
    100% {
        text-shadow: 0 0 5px #21AD58, 0 0 10px #21AD58, 0 0 20px #21AD58, 0 0 30px #21AD58;
    }
`;

// Styled components
const LoginContainer = styled.div`
    display: flex;
    flex-direction: row;
    height: 100vh;
    background-color: #fff;

    @media (max-width: 768px) {
        flex-direction: column;
        height: auto;
    }
`;

const LeftSection = styled.div`
    flex: 1;
    background-color: #000000;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    padding: 20px;

    @media (max-width: 768px) {
        padding: 10px;
    }
`;

const Logo = styled.div`
    font-size: 48px;
    font-weight: bold;
    color: white;
    text-align: center;
    span {
        display: block;
        margin-top: 15px;
    }

    @media (max-width: 768px) {
        font-size: 36px;
    }
`;

const Slogan = styled.div`
    font-size: 28px;
    color: #000000;
    text-align: center;
    animation: ${glowingEffect} 1.5s ease-in-out infinite;

    @media (max-width: 768px) {
        font-size: 24px;
    }
`;

const Separator = styled.div`
    width: 1px;
    background-color: #21AD58;
    box-shadow: 0 0 20px rgba(51, 255, 153, 0.8);
    transition: box-shadow 0.5s ease;

    @media (max-width: 768px) {
        display: none; /* Hide the separator on mobile */
    }
`;

const RightSection = styled.div`
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 50px;
    background-color: #000000;

    @media (max-width: 768px) {
        padding: 20px;
    }
`;

const LoginForm = styled.div`
    width: 100%;
    max-width: 400px;
    background-color: white;
    padding: 40px;
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);

    @media (max-width: 768px) {
        padding: 20px;
        max-width: 100%;
    }
`;

const Title = styled.h2`
    margin-bottom: 30px;
    color: #000000;
`;

const PasswordWrapper = styled.div`
    position: relative;
    width: 100%;
`;

const InputField = styled.input`
    width: 92%;
    padding: 15px;
    margin-bottom: 10px;
    border: 1px solid ${({ error }) => (error ? 'red' : '#ddd')};
    border-radius: 5px;
    font-size: 16px;
    outline: none;

    &:focus {
        border-color: ${({ error }) => (error ? 'red' : '#21AD58')};
    }
`;

const ToggleEyeButton = styled.button`
    position: absolute;
    right: 10px;
    top: 12px;
    background: none;
    border: none;
    cursor: pointer;
    font-size: 18px;
    color: ${({ error }) => (error ? 'red' : '#000000')};

    &:focus {
        outline: none;
    }
`;

const ErrorMessage = styled.div`
    color: red;
    font-size: 14px;
    margin-bottom: 10px;
`;

const SignInButton = styled.button`
    width: 100%;
    background-color: #000000;
    color: white;
    padding: 15px;
    border: none;
    border-radius: 5px;
    font-size: 18px;
    cursor: pointer;
    transition: background-color 0.3s;

    &:hover {
        background-color: #45a049;
    }

    &:disabled {
        background-color: #ccc;
        cursor: not-allowed;
    }
`;

const LoadingSpinner = styled.div`
    margin: 20px auto;
    border: 4px solid rgba(255, 255, 255, 0.3);
    border-radius: 50%;
    border-top: 4px solid #fff;
    width: 30px;
    height: 30px;
    animation: spin 0.8s linear infinite;

    @keyframes spin {
        0% { transform: rotate(0deg); }
        100% { transform: rotate(360deg); }
    }
`;

const Resetpassword = () => {
    const { token } = useParams(); // Get course id from the URL
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState({  password: false, confirmPassword: false });
    const [errorMessage, setErrorMessage] = useState({  password: '', confirmPassword: '' });
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const navigate = useNavigate();



    const submitForm = async () => {
        let isValid = true;
        setError({  password: false, confirmPassword: false });
        setErrorMessage({ password: '', confirmPassword: '' });



        // Regular expression to enforce complex password validation
        const passwordRegex = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[!@#\$&*~?_]).{8,}$/;

        if (!password) {
            setError((prev) => ({ ...prev, password: true }));
            setErrorMessage((prev) => ({ ...prev, password: 'Please enter a password.' }));
            isValid = false;
        } else if (!passwordRegex.test(password)) {
            setError((prev) => ({ ...prev, password: true }));
            setErrorMessage((prev) => ({ ...prev, password: 'Password must be at least 8 characters long, include uppercase, lowercase, a number, and a special character.' }));
            isValid = false;
        }

        if (password !== confirmPassword) {
            setError((prev) => ({ ...prev, confirmPassword: true }));
            setErrorMessage((prev) => ({ ...prev, confirmPassword: 'Passwords do not match.' }));
            isValid = false;
        }

        if (!isValid) return;

        setLoading(true);

        try {
            let engine = new RequestEngine();
            const data = {token: token ,newpass :  password,_csrf:token,_token:token}
            const response = await engine.postItem("resetnewpassword", data);
            setLoading(false);

            if (response && response.status === 200 && response.data.success) {
                Utilites.showSucessMessage("Password Changed!")
                navigate("/login");
            } else {
                Utilites.showErrorMessage(response.data.message);
            }
        } catch (error) {
            setLoading(false);
            Utilites.showErrorMessage("An error occurred. Please try again.");
        }
    };

    return (
        <LoginContainer>
            <LeftSection>
                <Logo>
                    <img src={logo} alt="Logo" width={300} />
                    <Slogan>Money Machine</Slogan>
                </Logo>
            </LeftSection>

            <Separator />

            <RightSection>
                <LoginForm>
                    <Title>Reset your Password</Title>
                    <p>Please enter your new password.</p>


                    <PasswordWrapper>
                        <InputField
                            type={showPassword ? "text" : "password"}
                            placeholder="New Password"
                            value={password}
                            error={error.password}
                            onChange={(e) => setPassword(e.target.value)}
                        />
                        <ToggleEyeButton
                            type="button"
                            onClick={() => setShowPassword(!showPassword)}
                            error={error.password}
                        >
                            {showPassword ? <FaEyeSlash /> : <FaEye />}
                        </ToggleEyeButton>
                    </PasswordWrapper>
                    {error.password && <ErrorMessage>{errorMessage.password}</ErrorMessage>}

                    <PasswordWrapper>
                        <InputField
                            type={showConfirmPassword ? "text" : "password"}
                            placeholder="Confirm Password"
                            value={confirmPassword}
                            error={error.confirmPassword}
                            onChange={(e) => setConfirmPassword(e.target.value)}
                        />
                        <ToggleEyeButton
                            type="button"
                            onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                            error={error.confirmPassword}
                        >
                            {showConfirmPassword ? <FaEyeSlash /> : <FaEye />}
                        </ToggleEyeButton>
                    </PasswordWrapper>
                    {error.confirmPassword && <ErrorMessage>{errorMessage.confirmPassword}</ErrorMessage>}

                    {loading ? (
                        <LoadingSpinner />
                    ) : (
                        <SignInButton onClick={submitForm} disabled={loading}>Submit</SignInButton>
                    )}
                </LoginForm>
            </RightSection>
        </LoginContainer>
    );
};

export default Resetpassword;
