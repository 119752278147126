import React, { useMemo, useEffect, useState } from "react";
import styled, { keyframes } from "styled-components";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../../App.css";
import RequestEngine from "../../core/RequestEngine";
import TopBar from "./TopBar";
import {
    Chart as ChartJS,
    LineElement,
    CategoryScale,
    LinearScale,
    PointElement,
    Tooltip,
    Legend,
} from "chart.js";
import { Utilites } from "../../core/Utilites";
import { Memory } from "../../core/Memory";
import VideoPlayer from "./VideoPlayer";
import Playlist from "./Playlist";

// Register the Chart.js components
ChartJS.register(LineElement, CategoryScale, LinearScale, PointElement, Tooltip, Legend);

// Styled Components

const Boxcontent = styled.div`
padding:50px;`


const HeroSection = styled.div`
    text-align: left;
    padding: 30px 20px;
    border-radius: 10px;
    margin-bottom: 20px;

    h1 {
        font-size: 72px;
        color: #B8B8B8;
        font-weight: bold;
        line-height: 1.5;

        span {
            color: #21AD58;
        }

        @media (max-width: 768px) {
            font-size: 38px;
        }
    }

    p {
        font-size: 40px;
        color: #B8B8B8;
        margin-top: 10px;
    

        b {
            color: #21AD58;
        }

        @media (max-width: 768px) {
            font-size: 16px;
        }
    }
`;

const GlowingRedText = styled.div`
    font-size: 16px;
    color: red;
margin-top: 60px;
    animation: ${keyframes`
        0% {
            text-shadow: 0 0 5px red, 0 0 10px red, 0 0 15px red, 0 0 20px red;
        }
        50% {
            text-shadow: 0 0 10px red, 0 0 20px red, 0 0 30px red, 0 0 40px red;
        }
        100% {
            text-shadow: 0 0 5px red, 0 0 10px red, 0 0 15px red, 0 0 20px red;
        }
    `} 1.5s ease-in-out infinite;
    text-align: center;
`;


const VideoPlayerWithPlaylistContainer = styled.div`
    display: flex;
    justify-content: center; /* Center the components horizontally */
    align-items: stretch; /* Ensure equal height for children */
    background-color: #0F211C;
    border-radius: 30px;
    gap: 0; /* Remove spacing between the components */
    @media (max-width: 768px) {
        flex-direction: column; /* Stack components vertically on smaller screens */
    }
`;

const VideoPlayerContainer = styled.div`
    display: flex;
    align-items: stretch; /* Ensure content fills the container */
    border-radius: 10px;
    overflow: hidden;
`;




const Dashboard = () => {



    const [topRank, setTopRank] = useState([]);
    const [topCoins, setTopCoins] = useState([]);

    const [topRecruitment, setTopRecruitment] = useState([]);



    const [loading, setLoading] = useState(true);
    const [accountBalance, setAccountBalance] = useState("0");
    const [dailyprofit, setDailyprofit] = useState("0");
    const [currentCredits, setCurrentCredits] = useState("0");
    const [coins, setCoins] = useState("0");

    const [referral, setReferral] = useState("");
    const [firstname, setFirstname] = useState("");
    const [userName, setUserName] = useState(""); // For the user's name

    const [rank, setRank] = useState("Unranked");
    useEffect(() => {
        const fetchData = async () => {
            const user = JSON.parse(Memory.getItem("userinfo"));
            setReferral(user.referral);
            setFirstname(user.first_name);

            setRank(user?.rank?.title || "Unranked");
            setUserName(user.username); // Fetch the username
        };
        fetchData();
    }, []);

    // Fetch data from the "user/home" endpoint using RequestEngine
    const fetchData = async () => {
        let engine = new RequestEngine();
        try {
            const response = await engine.getItem("user/home");
            if (response && response.status === 200 && response.data.data) {
                const overview = response.data.data.overview;

                if (overview) {
                    setAccountBalance(overview.accountBalance);
                    setDailyprofit(overview.dailyprofit);
                    setCurrentCredits(overview.currentCredits);
                    setCoins(overview.coins);
                }

                setTopRank(response.data.data.topRank)
                setTopRecruitment(response.data.data.topRecruitment)
                setTopCoins(response.data.data.topCoins)
            } else {
                console.error("Failed to fetch data:", response.data.message);
            }
        } catch (error) {
            console.error("Error while fetching data:", error);
        } finally {
            setLoading(false);
        }
    };

    // Get referral count by month for the past 12 months

    useEffect(() => {
        Utilites.setTimeout(() => {
            fetchData();
        }, 500);
    }, []);

    const [selectedVideo, setSelectedVideo] = useState(0);


    return (
        <Boxcontent>
            {referral.length === 0 && (
                <GlowingRedText>Please enter your referral code first</GlowingRedText>
            )}

            <HeroSection>
                <h1>
                    Let’s Make The Most <br />
                    Memorable <span>Adventure!</span>
                </h1>
                <p>
                    Welcome <b>{firstname}</b>, your username is <b>{userName}</b>, and your current <br/>rank is <b>{rank}</b>.
                </p>
            </HeroSection>

            <TopBar coins={coins} topCoins={topCoins} topRank={topRank} topRecruitment={topRecruitment} accountBalance={accountBalance} dailyprofit={dailyprofit} currentCredits={currentCredits} />

            <VideoPlayerWithPlaylistContainer>
                <VideoPlayerContainer>
                    <VideoPlayer/>
                </VideoPlayerContainer>
                <Playlist
                    onSelect={(index) => setSelectedVideo(index)}
                    selectedVideo={selectedVideo}
                />
            </VideoPlayerWithPlaylistContainer>


            </Boxcontent>
    );
};

export default Dashboard;
