import React, { useEffect, useRef, useState } from "react";
import styled, { keyframes } from "styled-components";
import icon1 from "../../../assets/images/Frame1.svg";
import icon2 from "../../../assets/images/Frame2.svg";
import icon3 from "../../../assets/images/Frame3.svg";
import icon4 from "../../../assets/images/Frame4.svg";
import icon5 from "../../../assets/images/Frame5.svg";
import icon6 from "../../../assets/images/Frame1.svg";
import bg from "../../../assets/images/BG_Arrow.svg";

// Animations
const fadeInUp = keyframes`
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
`;

const fadeInCard = keyframes`
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
`;

const Content = styled.div`
  margin-top: 50px;
  padding: 20px;
  gap: 20px;
  color: #fff;
  width: 90%;
  max-width: 1200px;
  margin: 0 auto;
  background-image: url(${bg});
  background-position: center;
  background-repeat: no-repeat;

  @media (max-width: 768px) {
    padding: 20px 10px;
    background-size: cover;
  }
`;

const TextSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: left;
  margin-bottom: 30px;
  width: 100%;
  animation: ${fadeInUp} 1s ease-out;

  @media (max-width: 768px) {
    align-items: center;
    text-align: center;
  }
`;

const Title = styled.h1`
  color: #fff;
  font-size: 48px;
  font-weight: bold;
  margin-bottom: 10px;
  animation: ${fadeInUp} 1s ease-out 0.2s forwards;

  @media (max-width: 768px) {
    font-size: 36px;
  }

  @media (max-width: 480px) {
    font-size: 28px;
  }
`;

const Subtitle = styled.p`
  font-size: 18px;
  line-height: 1.5;
  margin-bottom: 20px;
  color: #b3b3b3;

  @media (max-width: 768px) {
    font-size: 16px;
  }

  @media (max-width: 480px) {
    font-size: 14px;
  }
`;

const Card = styled.div`
  padding: 20px;
  opacity: 0;
  transform: translateY(20px);
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 8px;
  display: flex;
  align-items: flex-start;
  gap: 15px;
  animation: ${fadeInCard} 1s ease-out forwards;
  animation-delay: ${(props) => props.delay || "0s"};

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
`;

const CardIcon = styled.img`
  width: 50px;
  height: 50px;
  object-fit: contain;

  @media (max-width: 480px) {
    width: 40px;
    height: 40px;
  }
`;

const CardText = styled.div`
  display: flex;
  flex-direction: column;
  text-align: left;

  @media (max-width: 768px) {
    text-align: center;
  }
`;

const CardTitle = styled.p`
  font-size: 28px;
  font-weight: bold;
  margin: 0;
  color: #b8b8b8;

  @media (max-width: 768px) {
    font-size: 24px;
  }

  @media (max-width: 480px) {
    font-size: 20px;
  }
`;

const CardSubtitle = styled.p`
  font-size: 14px;
  color: #b3b3b3;
  margin-top: 5px;

  @media (max-width: 768px) {
    font-size: 12px;
  }

  @media (max-width: 480px) {
    font-size: 10px;
  }
`;

const KeyPointsWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  width: 100%;

  @media (max-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: 480px) {
    grid-template-columns: 1fr;
  }
`;

const Section7 = () => {
  const [isVisible, setIsVisible] = useState(false);
  const sectionRef = useRef(null);

  // Set up intersection observer
  useEffect(() => {
    const observer = new IntersectionObserver(
        (entries) => {
          if (entries[0].isIntersecting) {
            setIsVisible(true);
          }
        },
        { threshold: 0.5 } // Trigger when 50% of the section is visible
    );

    if (sectionRef.current) {
      observer.observe(sectionRef.current);
    }

    return () => {
      if (sectionRef.current) {
        observer.unobserve(sectionRef.current);
      }
    };
  }, []);

  const points = [
    {
      icon: icon1,
      title: "Partner",
      subtitle: "Partner with Multyncome and start earning extra income.",
    },
    {
      icon: icon2,
      title: "Program",
      subtitle:
          "Earn commissions by referring friends and family, and learn essential skills for generating online income.",
    },
    {
      icon: icon3,
      title: "Connections",
      subtitle:
          "Unlock a world of income potential while building meaningful connections and expanding your financial knowledge.",
    },
    {
      icon: icon4,
      title: "Skills",
      subtitle: "Learn the skills you need to build a sustainable online income.",
    },
    {
      icon: icon5,
      title: "Grow",
      subtitle: "Comprehensive support to help you grow.",
    },
    {
      icon: icon6,
      title: "Earning",
      subtitle: "Profitable referral programs to maximize your earnings.",
    },
  ];

  return (
      <Content ref={sectionRef}>
        {/* Text Section */}
        <TextSection>
          <Title>
            The best features for
            <br /> users convenience
          </Title>
          <Subtitle>Partnership Opportunity and Affiliate Program</Subtitle>
        </TextSection>

        <KeyPointsWrapper>
          {points.map((point, index) => (
              <Card
                  key={index}
                  delay={`${index * 0.2}s`}
                  style={{ animationPlayState: isVisible ? "running" : "paused" }}
              >
                <CardIcon src={point.icon} alt={`Icon for ${point.title}`} />
                <CardText>
                  <CardTitle>{point.title}</CardTitle>
                  <CardSubtitle>{point.subtitle}</CardSubtitle>
                </CardText>
              </Card>
          ))}
        </KeyPointsWrapper>
      </Content>
  );
};

export default Section7;
