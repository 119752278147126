import React from "react";
import styled, { keyframes } from "styled-components";
import top1 from "../../../assets/images/top1.png";

// Animations
const fadeIn = keyframes`
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
`;

const pixelateImage = keyframes`
    0% {
        filter: blur(10px);
        transform: scale(1.2);
    }
    100% {
        filter: blur(0);
        transform: scale(1);
    }
`;

const Content = styled.div`
    width: 90%;
    max-width: 1200px;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    margin-top: 50px;
    padding: 0 20px;
    gap: 20px;

    @media (max-width: 768px) {
        flex-direction: column;
        text-align: center;
    }
`;

const Section = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    animation: ${fadeIn} 1.5s ease-out;

    @media (max-width: 768px) {
        width: 100%;
    }
`;

const TextSection = styled(Section)`
    align-items: flex-start;
    text-align: left;

    @media (max-width: 768px) {
        align-items: center;
        text-align: center;
    }
`;

const Title = styled.h1`
    color: #fff;
    font-size: 48px;
    line-height: 1.2;
    margin-bottom: 20px;
    display: flex;
    flex-wrap: wrap;

    span {
        opacity: 0;
        animation: ${fadeIn} 0.5s forwards;
        animation-delay: ${({ index }) => index * 0.3}s;
    }

    @media (max-width: 768px) {
        font-size: 36px;
    }

    @media (max-width: 480px) {
        font-size: 28px;
    }
`;

const Subtitle = styled.p`
    font-size: 16px;
    margin-bottom: 10px;
    color: #b3b3b3;
    line-height: 25.2px;

    @media (max-width: 768px) {
        font-size: 14px;
    }
`;

const Goal = styled.p`
    font-size: 18px;
    font-weight: bold;
    color: #b3b3b3;

    @media (max-width: 768px) {
        font-size: 16px;
    }
`;

const ImageSection = styled(Section)`
    flex: 2;
    img {
        max-width: 100%;
        height: auto;
        animation: ${pixelateImage} 2s ease-out;

        @media (max-width: 768px) {
            margin-top: 20px;
        }
    }
`;

const StatsSection = styled(Section)`
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    @media (max-width: 768px) {
        align-items: center;
    }
`;

const Stat = styled.div`
    background-color: rgba(255, 255, 255, 0.1);
    border-radius: 5px;
    padding: 15px;
    font-size: 18px;
    text-align: left;
    margin-bottom: 15px;
    width: 100%;
    max-width: 212px;
    height: 61px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background: linear-gradient(
            150.39deg,
            rgba(237, 242, 248, 0.0264) 15.02%,
            rgba(237, 242, 248, 0.12) 99.59%
    );
    border: 1px solid transparent;
    transition: box-shadow 0.3s ease-in-out;

    &:hover {
        box-shadow: 0 0 10px rgba(50, 205, 50, 0.7); /* Optional glow effect */
    }

    @media (max-width: 768px) {
        width: 90%;
    }
`;

const StatNumber = styled.span`
    font-size: 24px;
    font-weight: bold;
    color: #fff;

    @media (max-width: 768px) {
        font-size: 20px;
    }
`;

const StatText = styled.span`
    font-size: 14px;
    color: #b3b3b3;

    @media (max-width: 768px) {
        font-size: 12px;
    }
`;

const Section1 = () => {
    const title = "Your Journey To Success With Multyncome";
    return (
        <Content>
            <TextSection>
                <Title>
                    {title.split(" ").map((word, index) => (
                        <span
                            key={index}
                            style={{ marginLeft: 5, animationDelay: `${index * 0.3}s` }}
                        >
              {" "}
                            {word}{" "}
            </span>
                    ))}
                </Title>
                <Subtitle>
                    Step 1: “Begin your journey with AI-powered investments to achieve
                    impressive returns effortlessly.”
                </Subtitle>
                <Goal>
                    Goal: Experience smart investing and lay the foundation for financial
                    growth.
                </Goal>
            </TextSection>

            <ImageSection>
                <img src={top1} alt="Multyncome" />
            </ImageSection>

            <StatsSection>
                <Stat>
                    <StatNumber>10 Years</StatNumber>
                    <StatText>Trading Experience</StatText>
                </Stat>
                <Stat>
                    <StatNumber>24/7</StatNumber>
                    <StatText>Online Support</StatText>
                </Stat>
                <Stat>
                    <StatNumber>25K+</StatNumber>
                    <StatText>Satisfied Customers</StatText>
                </Stat>
            </StatsSection>
        </Content>
    );
};

export default Section1;
