import React, { useState, useEffect, useRef } from "react";
import styled, { keyframes, css } from "styled-components";
import leftarrow from "../../../assets/images/arrow.svg";
import rightarrow from "../../../assets/images/leftarrow.svg";

// Animations
const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const slideInBottom = keyframes`
  from {
    transform: translateY(50%);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
`;

const popIn = keyframes`
  from {
    transform: scale(0.8);
    opacity: 0;
  }
  to {
    transform: scale(1);
    opacity: 1;
  }
`;

// Styled Components
const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: left;
  background-color: #0d221c;
  color: #fff;
  padding: 50px 20px;
  opacity: 0;
  ${({ isVisible }) =>
      isVisible &&
      css`
      animation: ${fadeIn} 1s ease-out forwards;
    `}
`;

const Title = styled.h2`
  font-size: 32px;
  font-weight: bold;
  margin-bottom: 10px;
  align-items: left;
  padding-left: 100px;
`;

const Subtitle = styled.p`
  font-size: 18px;
  margin-bottom: 40px;
  color: #b3b3b3;
  align-items: left;
  padding-left: 100px;
`;

const ReviewsWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
  width: 100%;
  max-width: 1800px;
  position: relative;
  background-color: #0d221c;
  overflow: hidden;
`;

const ReviewCard = styled.div`
  flex: 1;
  max-width: ${(props) => (props.isLarge ? "633px" : "450px")};
  height: ${(props) => (props.isLarge ? "250px" : "200px")};
  border: 1px solid #00ffcc;
  border-radius: 10px;
  padding: 50px;
  text-align: left;
  position: relative;
  color: #fff;
  opacity: 0;
  ${({ isVisible, delay }) =>
      isVisible &&
      css`
      animation: ${slideInBottom} 1s ease-out ${delay}s forwards;
    `}
`;

const ArrowsGroup = styled.div`
  display: flex;
  gap: 10px;
  opacity: 0;
  ${({ isVisible }) =>
      isVisible &&
      css`
      animation: ${popIn} 0.5s ease-out forwards;
    `}
`;

const NavButton = styled.button`
  background: none;
  border: 2px solid #fff;
  color: #fff;
  border-radius: 50%;
  padding: 20px;
  cursor: pointer;
  transition: all 0.3s ease;

  &:hover,
  &:active {
    border-color: #22ad58;
    color: #22ad58;

    img {
      filter: invert(45%) sepia(100%) saturate(350%) hue-rotate(125deg)
      brightness(95%) contrast(90%);
    }
  }

  img {
    transform: ${(props) =>
        props.direction === "right" ? "rotate(180deg)" : "none"};
    transition: transform 0.3s ease, filter 0.3s ease;
  }
`;

// Main Component
const CustomerReviews = () => {
  const [isVisible, setIsVisible] = useState(false);
  const sectionRef = useRef(null);

  const reviews = [
    {
      name: "Sarah T.",
      role: "Crypto Trader",
      rating: 4,
      quote:
          "The fast transactions and low fees are a game-changer! Highly recommend BlockStream.",
    },
    {
      name: "Emily R.",
      role: "Entrepreneur",
      rating: 5,
      quote:
          "BlockStream's user-friendly platform and top-notch security give me peace of mind knowing my assets are safe.",
    },
    {
      name: "David L.",
      role: "Investor",
      rating: 5,
      quote:
          "This platform has completely transformed how I approach crypto investments. Trustworthy and efficient!",
    },
    {
      name: "Tarek D",
      role: "",
      rating: 4,
      quote:
          "Automated trading removes stress, and the community-building aspect keeps users engaged.",
    },
  ];

  const [currentIndex, setCurrentIndex] = useState(0);
  const reviewsToShow = 3;

  const handlePrev = () => {
    setCurrentIndex((prevIndex) => {
      const newIndex = prevIndex - reviewsToShow;
      return newIndex < 0 ? 0 : newIndex;
    });
  };

  const handleNext = () => {
    setCurrentIndex((prevIndex) => {
      const newIndex = prevIndex + reviewsToShow;
      return newIndex >= reviews.length ? prevIndex : newIndex;
    });
  };

  const visibleReviews = reviews.slice(currentIndex, currentIndex + reviewsToShow);

  useEffect(() => {
    const observer = new IntersectionObserver(
        ([entry]) => {
          if (entry.isIntersecting) {
            setIsVisible(true);
          }
        },
        {
          threshold: 0.3,
        }
    );

    if (sectionRef.current) {
      observer.observe(sectionRef.current);
    }

    return () => {
      if (sectionRef.current) {
        observer.unobserve(sectionRef.current);
      }
    };
  }, []);

  return (
      <Content ref={sectionRef} isVisible={isVisible}>
        <Title>What Our Users Are Saying</Title>
        <Subtitle>
          Join Thousands of Satisfied Customers Who Trust BlockStream for Their
          Crypto Transactions
        </Subtitle>
        <ReviewsWrapper>
          {visibleReviews.map((review, index) => (
              <ReviewCard
                  key={index}
                  isLarge={index === 1}
                  isVisible={isVisible}
                  delay={index * 0.2} // Stagger effect
              >
                <div>
                  <h3>{review.name}</h3>
                  <p>{review.quote}</p>
                </div>
              </ReviewCard>
          ))}
        </ReviewsWrapper>
        <ArrowsGroup isVisible={isVisible}>
          <NavButton direction="left" onClick={handlePrev}>
            <img src={leftarrow} alt="leftarrow" width="25px" />
          </NavButton>
          <NavButton direction="right" onClick={handleNext}>
            <img src={rightarrow} alt="rightarrow" width="25px" />
          </NavButton>
        </ArrowsGroup>
      </Content>
  );
};

export default CustomerReviews;
