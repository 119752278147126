import React, {useEffect, useState} from "react";
import styled, { keyframes } from "styled-components";
import {Memory} from "../../core/Memory";

// Glowing effect for missing referral message
const glowingEffect = keyframes`
    0% {
        text-shadow: 0 0 5px red, 0 0 10px red, 0 0 15px red, 0 0 20px red;
    }
    50% {
        text-shadow: 0 0 10px red, 0 0 20px red, 0 0 30px red, 0 0 40px red;
    }
    100% {
        text-shadow: 0 0 5px red, 0 0 10px red, 0 0 15px red, 0 0 20px red;
    }
`;

// Main container for the screen
const InvestmentContainer = styled.div`
    background-color: #000;
    padding: 20px;
    color: white;
`;

// Header for the screen
const Header = styled.h1`
    font-size: 24px;
    margin-bottom: 20px;
    color: #21AD58;
`;

// Steps container
const StepsContainer = styled.div`
    background-color: #0f211c;
    border-radius: 10px;
    padding: 20px;
    margin-bottom: 20px;
    color: white;
`;

// Each step
const StepItem = styled.div`
    margin-bottom: 10px;
    font-size: 16px;
    display: flex;
    align-items: center;

    a {
        background-color: #21ad58;
        color: white;
        text-decoration: none;
        padding: 8px 12px;
        border-radius: 5px;
        margin-left: 10px;

        &:hover {
            background-color: #21AD58;
        }
    }
`;

// Plans container
const PlansContainer = styled.div`
    display: flex;
    gap: 20px;
    justify-content: space-between;
    flex-wrap: wrap;
`;

// Single plan card
const PlanCard = styled.div`
    flex: 1;
    min-width: 200px;
    background-color:"#0f211c";
    color: ${(props) => (props.selected ? "white" : "#d8e4e1")};
    border: ${(props) => (props.selected ? "2px solid #21AD58" : "1px solid #3b3d3b")};
    border-radius: 10px;
    padding: 20px;
    text-align: center;
    cursor: pointer;
    transition: all 0.3s ease;

    &:hover {
        background-color: ${(props) => (props.selected ? "#21AD58" : "#162421")};
    }
    ul{
        text-align: left;
    }

`;

// Button for plan selection
const SelectButton = styled.a`
    background-color: #21ad58;
    color: white;
    border: none;
    padding: 15px 30px;
    font-size: 16px;
    border-radius: 10px;
    margin-top: 20px;
    cursor: pointer;
    text-align: center;
    display: inline-block;
    text-decoration: none; /* Remove underline */

    &:hover {
        background-color: #21AD58;
    }
`;

// Glowing text for referral error
const GlowingText = styled.div`
    color: red;
    font-size: 16px;
    text-align: center;
    animation: ${glowingEffect} 1.5s ease-in-out infinite;
    margin-bottom: 20px;
`;

const InvestmentScreen = () => {
    const [selectedPlan, setSelectedPlan] = useState(null);

    const [ismember, setIsmember] = useState(false);
    const [referral, setReferral] = useState("");
    useEffect(() => {
        const fetchData = async () => {
            const user = JSON.parse(Memory.getItem("userinfo"));
            setReferral(user.referral);
            setIsmember(user.ismember)
        };
        debugger
        fetchData();
    }, []);
    // Hardcoded plans data
    const plans = [
        {
            id: "100$ to 2500$ (50% fees)",
            title: "100$ to 2500$ (50% fees)",
            link: "https://vc.cabinet.oneroyal.com/brokeree-pamm/investments/register/440/",
        },
        {
            id: "2501$ to 10k$ (45% fees)",
            title: "2501$ to 10k$ (45% fees)",
            link: "https://vc.cabinet.oneroyal.com/brokeree-pamm/investments/register/441/",
        },
        {
            id: "10k$ and above (40% fees)",
            title: "10k$ and above (40% fees)",
            link: "https://vc.cabinet.oneroyal.com/brokeree-pamm/investments/register/442/",
        },
    ];

    const handlePlanSelect = (planId) => {
        setSelectedPlan(planId);
    };

    const handleEnrollClick = () => {
        console.log("Plan selected:", selectedPlan);
        alert("Plan selected: " + selectedPlan);
    };

    return (
        <div>
            <h2 id="title">Investment</h2>
            <InvestmentContainer>
                <Header>For Non-Members</Header>

                {/* Steps */}
                <StepsContainer>
                    <StepItem>
                        Step 1: Register now using this link:{" "}
                        <a href="https://vc.cabinet.oneroyal.com/links/go/5949" target="_blank" rel="noopener noreferrer">
                            Register
                        </a>
                    </StepItem>
                    <StepItem>
                        Step 2: Complete your verification (Password/ID, Proof of Address, Email)
                    </StepItem>
                    <StepItem>Step 3: Select your agreement for customers</StepItem>
                </StepsContainer>

                {/* Plans */}
                <PlansContainer>
                    {plans.map((plan) => (
                        <PlanCard
                            key={plan.id}
                            selected={selectedPlan === plan.id}
                            onClick={() => handlePlanSelect(plan.id)}
                        >
                            <h3>{plan.title}</h3>

                            <SelectButton href={plan.link} target="_blank">Press here</SelectButton>
                        </PlanCard>
                    ))}
                </PlansContainer>
            </InvestmentContainer>
            {ismember && <InvestmentContainer>
                <Header>For Members / Always 35% </Header>

                {/* Steps */}
                <StepsContainer>
                    <StepItem>
                        If your amount is below 5000$:
                        <a href="https://vc.cabinet.oneroyal.com/brokeree-pamm/investment-link/339/MultyncomeBronze/?pid=79650" target="_blank" rel="noopener noreferrer">
                            Press here
                        </a>
                    </StepItem>

                    <StepItem>
                        If your amount is 5000$ and above:
                        <a href="https://vc.cabinet.oneroyal.com/brokeree-pamm/investment-link/338/MultyncomeGold/?pid=79650" target="_blank" rel="noopener noreferrer">
                            Press here
                        </a>
                    </StepItem>

                </StepsContainer>


            </InvestmentContainer>}

        </div>
    );
};

export default InvestmentScreen;
