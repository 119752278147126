import React from "react";
import styled from "styled-components";
import check from "../../../assets/images/check-box.svg";

const BoxedContent = styled.div`
  background-color: #0d221c;
  width: 100%;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: left;
  background-color: #0d221c;
  color: #fff;
  padding: 50px 20px;
  width: 80%;
  margin: 0 auto;
`;

const Header = styled.div`
  text-align: left;
  margin-bottom: 40px;
`;

const Subtitle = styled.p`
  font-size: 18px;
  color: #ffffff;
  align-items: left;
`;

const Title = styled.h2`
  font-size: 36px;
  font-weight: bold;
  margin: 10px 0;
`;

const PlansWrapper = styled.div`
  display: flex;
  justify-content: space-between; /* Distribute the cards evenly across the available width */
  gap: 20px;
  width: 100%;
  flex-wrap: wrap; /* Allow wrapping for responsiveness */
`;

const PlanCard = styled.div`
  background-color: #00150f;
  border: 1px solid #222;
  border-radius: 10px;
  padding: 30px 20px;
  text-align: left;
  flex: 1; /* Each card takes an equal share of the available width */
  min-width: 230px; /* Minimum width to ensure cards don't get too narrow */
  box-shadow: none; /* Removed the box-shadow for uniformity */
  transition: all 0.3s ease; /* Smooth transition for all properties */

  /* Hover effect */
  &:hover {
    background-color: #003c2b; /* Slightly lighter background color on hover */
    transform: translateY(-5px); /* Slightly lift the card */
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2); /* Add a subtle shadow */
  }
`;



const PlanTitle = styled.h3`
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 10px;
  color: #22ad58; /* Set a fixed color for all titles */
`;

const Price = styled.p`
  font-size: 28px;
  font-weight: bold;
  margin-bottom: 20px;
  color: #22ad58; /* Set a fixed color for all prices */
`;

const FeaturesList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0 0 20px 0;
  text-align: left;
  color: #b3b3b3;
`;

const Feature = styled.li`
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  gap: 10px;
`;

const CheckIcon = styled.span`
  font-size: 16px;
  color: #22ad58;
`;

const Button = styled.button`
  background-color: #22ad58;
  border: 1px solid #22ad58;
  border-radius: 5px;
  padding: 10px 20px;
  font-size: 16px;
  color: #0f1923;
  cursor: pointer;
  transition: all 0.3s ease;

  &:hover {
    background-color: #22ad58;
    color: #0f1923;
  }
`;

const GreenText = styled.span`
  color: #22ad58; /* Green color for percentage */
`;

// Main Component
const FeeStructure = () => {
  const plans = [
    {
      title: "BASIC",
      price: "50% / 100$ To 2500$",
      features: [
        "Weekly online meeting",
        "Unlimited learning access",
        "24/7 technical support",
        "Personal training",
      ],
      button: "Choose Plan",
    },
    {
      title: "STANDARD",
      price: "45% / 2500$ To 5,000$",
      features: [
        "Weekly online meeting",
        "Unlimited learning access",
        "24/7 technical support",
        "Personal training",
      ],
      button: "Choose Plan",
    },
    {
      title: "PREMIUM",
      price: "40% / 5,000$ And Above",
      features: [
        "Weekly online meeting",
        "Unlimited learning access",
        "24/7 technical support",
        "Personal training",
      ],
      button: "Choose Plan",
    },
    {
      title: "EXCLUSIVE RATE",
      price: "35% / Fee For Members",
      features: [
        "Monthly online meeting",
        "Limited learning access",
        "24/7 technical support",
        "Personal training",
      ],
      button: "Get Access",
    },
  ];

  return (
      <BoxedContent>
        <Content>
          <Header>
            <Subtitle>Invest Smartly</Subtitle>
            <Title>Fee Structure</Title>
            <Subtitle>
              “Enjoy easy income with AI-driven investments and build a wealth mindset.”
            </Subtitle>
          </Header>
          <PlansWrapper>
            {plans.map((plan, index) => (
                <PlanCard key={index}>
                  <PlanTitle>{plan.title}</PlanTitle>
                  <Price><GreenText>{plan.price.split(" ")[0]}</GreenText> {plan.price.split(" ").slice(1).join(" ")}</Price>

                  <FeaturesList>
                    {plan.features.map((feature, idx) => (
                        <Feature key={idx}>
                          <CheckIcon>
                            <img src={check} alt="Check Icon" />
                          </CheckIcon>
                          {feature}
                        </Feature>
                    ))}
                  </FeaturesList>
                  <Button>{plan.button}</Button>
                </PlanCard>
            ))}
          </PlansWrapper>
          <Subtitle>
            <b>Rewards & Recognition:</b> Unlock luxury rewards like cars and gain recognition.
          </Subtitle>
        </Content>
      </BoxedContent>
  );
};

export default FeeStructure;
