import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { FaArrowUp, FaArrowDown } from "react-icons/fa";
import RequestEngine from "../../core/RequestEngine";

// Main container for the activity screen
const ActivityContainer = styled.div`
  background-color: #00160f;
  min-height: 100vh;
  padding: 20px;
  color: white;
  font-family: "Inter", sans-serif;
`;

// Table wrapper with rounded corners and padding
const TableWrapper = styled.div`
    background-color: #0f211c;
    border-radius: 12px;
    padding: 20px;
    margin-top: 20px;
`;

// Styled table with custom headers and row styling
const StyledTable = styled.table`
    width: 100%;
    border-collapse: collapse;
    font-size: 16px;

    th {
        background-color: #00160F;
        color: #21AD58;
        font-weight: bold;
        padding: 12px;
        text-align: left;
        border-bottom: 2px solid #1f392e;
    }

    td {
        padding: 12px;
        border-bottom: 1px solid #1f392e;
        color: #d1d1d1;
        vertical-align: middle;
    }

    td.amount-negative {
        color: #e74c3c;
    }

    td.amount-positive {
        color: #2ecc71;
    }

    tr:hover {
        background-color: #193529;
        transition: background-color 0.2s ease;
    }
`;

// Title for the activity screen
const Title = styled.h2`
    color: white;
    font-weight: bold;
    margin-bottom: 20px;
`;

// Activity screen component
const ActivityScreen = () => {
    const [activityData, setActivityData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [sortConfig, setSortConfig] = useState({ key: null, direction: "ascending" });

    useEffect(() => {
        let mounted = true;
        if (loading) return;

        const fetchData = async () => {
            setLoading(true);
            let engine = new RequestEngine();
            try {
                const response = await engine.getItem("user/me");
                if (response && response.status === 200 && response.data && mounted) {
                    setActivityData(response.data.data.userhistory);
                }
            } catch (error) {
                console.error("An error occurred while fetching activity data:", error);
            } finally {
                if (mounted) setLoading(false);
            }
        };

        fetchData();

        return () => {
            mounted = false;
        };
    }, []);

    const sortData = (key) => {
        let direction = "ascending";
        if (sortConfig.key === key && sortConfig.direction === "ascending") {
            direction = "descending";
        }

        const sortedData = [...activityData].sort((a, b) => {
            if (key === "amount") {
                return direction === "ascending" ? a[key] - b[key] : b[key] - a[key];
            } else if (key === "createdAt") {
                return direction === "ascending"
                    ? new Date(a[key]) - new Date(b[key])
                    : new Date(b[key]) - new Date(a[key]);
            } else {
                return direction === "ascending" ? a[key].localeCompare(b[key]) : b[key].localeCompare(a[key]);
            }
        });

        setActivityData(sortedData);
        setSortConfig({ key, direction });
    };

    const getSortIcon = (key) => {
        if (sortConfig.key !== key) return null;
        if (sortConfig.direction === "ascending") return <FaArrowUp />;
        return <FaArrowDown />;
    };

    if (loading) {
        return (
            <ActivityContainer>
                <h2>Loading Activity...</h2>
            </ActivityContainer>
        );
    }

    return (
        <ActivityContainer>
            <Title id={"title"}>Activity</Title>
            <TableWrapper>
                <StyledTable>
                    <thead>
                    <tr >
                        <th onClick={() => sortData("name")}>
                            Title {getSortIcon("name")}
                        </th>
                        <th onClick={() => sortData("createdAt")}>
                            Date {getSortIcon("createdAt")}
                        </th>
                        <th onClick={() => sortData("amount")}>
                            Amount {getSortIcon("amount")}
                        </th>
                    </tr>
                    </thead>
                    <tbody>
                    {activityData?.map((item, index) => (
                        <tr key={index}>
                            <td>
                                {item.name === "DEDUCT" ? (
                                    <>
                                        <FaArrowDown className="arrow-down" />{" "}
                                        <span style={{ color: "#e74c3c" }}>{item.name}</span>
                                    </>
                                ) : (
                                    <>
                                        <FaArrowUp className="arrow-up" />{" "}
                                        <span style={{ color: "#2ecc71" }}>{item.name}</span>
                                    </>
                                )}
                            </td>
                            <td>{new Date(item.createdAt).toLocaleDateString()}</td>
                            <td
                                className={item.amount < 0 ? "amount-negative" : "amount-positive"}
                            >
                                {item.amount.toFixed(2)}
                            </td>
                        </tr>
                    ))}
                    </tbody>
                </StyledTable>
            </TableWrapper>
        </ActivityContainer>
    );
};

export default ActivityScreen;
