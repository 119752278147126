import React, { useRef, useState } from "react";
import styled from "styled-components";
import { FaPlay, FaPause, FaVolumeUp, FaExpand, FaVolumeMute } from "react-icons/fa";

// Styled Components
const VideoPlayerContainer = styled.div`
    position: relative;
    background-color: #000000;
    border-radius: 15px;
    overflow: hidden;
    width: 100%;
    margin: 20px auto;

    @media (max-width: 768px) {
        border-radius: 10px;
    }
`;

const VideoElement = styled.video`
    width: 100%;
    height: auto;

    @media (max-width: 768px) {
        height: 200px;
    }
`;

const Controls = styled.div`
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    color: white;

    @media (max-width: 768px) {
        padding: 8px;
        flex-wrap: wrap;
    }
`;

const ControlButton = styled.button`
    background: none;
    border: none;
    color: white;
    font-size: 20px;
    cursor: pointer;
    display: flex;
    align-items: center;

    &:hover {
        color: #21AD58;
    }

    @media (max-width: 768px) {
        font-size: 18px;
    }
`;

const ProgressContainer = styled.div`
    flex: 1;
    margin: 0 10px;
    display: flex;
    align-items: center;

    @media (max-width: 768px) {
        margin: 0 5px;
    }
`;

const ProgressBar = styled.input`
    width: 100%;
    height: 8px;
    cursor: pointer;
    background: transparent;
    -webkit-appearance: none;

    &::-webkit-slider-runnable-track {
        background: #3b3d3b;
        height: 5px;
        border-radius: 3px;
    }

    &::-webkit-slider-thumb {
        -webkit-appearance: none;
        width: 12px;
        height: 12px;
        background: #21AD58;
        border-radius: 50%;
        cursor: pointer;
        margin-top: -4px;
    }

    @media (max-width: 768px) {
        height: 6px;

        &::-webkit-slider-thumb {
            width: 10px;
            height: 10px;
        }
    }
`;

const TimeDisplay = styled.span`
    font-size: 14px;
    color: #bbb;
    min-width: 60px;
    text-align: center;

    @media (max-width: 768px) {
        font-size: 12px;
        min-width: 50px;
    }
`;

const VolumeBar = styled.input`
    width: 100px;
    height: 8px;
    cursor: pointer;
    background: transparent;
    -webkit-appearance: none;

    &::-webkit-slider-runnable-track {
        background: #3b3d3b;
        height: 5px;
        border-radius: 3px;
    }

    &::-webkit-slider-thumb {
        -webkit-appearance: none;
        width: 12px;
        height: 12px;
        background: #21AD58;
        border-radius: 50%;
        cursor: pointer;
        margin-top: -4px;
    }

    @media (max-width: 768px) {
        width: 80px;
        height: 6px;

        &::-webkit-slider-thumb {
            width: 10px;
            height: 10px;
        }
    }
`;

const CoursePlayer = ({ url }) => {
    const videoRef = useRef(null);

    const [isPlaying, setIsPlaying] = useState(false);
    const [currentTime, setCurrentTime] = useState(0);
    const [duration, setDuration] = useState(0);
    const [volume, setVolume] = useState(1);
    const [isMuted, setIsMuted] = useState(false);

    const togglePlayPause = () => {
        if (isPlaying) {
            videoRef?.current.pause();
        } else {
            videoRef?.current.play();
        }
        setIsPlaying(!isPlaying);
    };

    const handleTimeUpdate = () => {
        setCurrentTime(videoRef.current.currentTime);
    };

    const handleLoadedMetadata = () => {
        setDuration(videoRef.current.duration);
    };

    const handleProgressChange = (e) => {
        const seekTime = (e.target.value / 100) * duration;
        videoRef.current.currentTime = seekTime;
        setCurrentTime(seekTime);
    };

    const handleVolumeChange = (e) => {
        const newVolume = e.target.value / 100;
        videoRef.current.volume = newVolume;
        setVolume(newVolume);
        setIsMuted(newVolume === 0);
    };

    const toggleMute = () => {
        setIsMuted(!isMuted);
        videoRef.current.volume = isMuted ? volume : 0;
    };

    const toggleFullscreen = () => {
        if (videoRef.current.requestFullscreen) {
            videoRef.current.requestFullscreen();
        }
    };

    const formatTime = (time) => {
        const minutes = Math.floor(time / 60);
        const seconds = Math.floor(time % 60);
        return `${minutes}:${seconds < 10 ? `0${seconds}` : seconds}`;
    };

    return (
        <VideoPlayerContainer>
            <VideoElement
                ref={videoRef}
                src={url}
                onTimeUpdate={handleTimeUpdate}
                onLoadedMetadata={handleLoadedMetadata}
                controls={false}
            />
            <Controls>
                <ControlButton onClick={togglePlayPause}>
                    {isPlaying ? <FaPause /> : <FaPlay />}
                </ControlButton>
                <ProgressContainer>
                    <TimeDisplay>{formatTime(currentTime)}</TimeDisplay>
                    <ProgressBar
                        type="range"
                        min="0"
                        max="100"
                        value={(currentTime / duration) * 100 || 0}
                        onChange={handleProgressChange}
                    />
                    <TimeDisplay>{formatTime(duration)}</TimeDisplay>
                </ProgressContainer>
                <ControlButton onClick={toggleMute}>
                    {isMuted ? <FaVolumeMute /> : <FaVolumeUp />}
                </ControlButton>
                <VolumeBar
                    type="range"
                    min="0"
                    max="100"
                    value={volume * 100}
                    onChange={handleVolumeChange}
                />
                <ControlButton onClick={toggleFullscreen}>
                    <FaExpand />
                </ControlButton>
            </Controls>
        </VideoPlayerContainer>
    );
};

export default CoursePlayer;
