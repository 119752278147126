import styled from "styled-components";
import trophie from "../../assets/images/trophie.png";

const ProfileCardSection = styled.div`
    display: flex;
    justify-content: space-between;
    gap: 20px;
    flex-wrap: wrap;
    border-radius: 30px;
    padding: 20px;
    margin-bottom: 30px;

    @media (max-width: 1024px) {
        gap: 15px;
    }

    @media (max-width: 768px) {
        flex-direction: column;
        align-items: center;
        gap: 20px;
    }
`;

const ProfileCard = styled.div`
    background-color: #1a2e26;
    border: 1px solid #3b3d3b;
    border-radius: 15px;
    padding: 20px;
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-width: 250px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
    transition: transform 0.3s ease, box-shadow 0.3s ease;

    &:hover {
        transform: translateY(-5px);
        box-shadow: 0 6px 10px rgba(0, 0, 0, 0.3);
    }

    @media (max-width: 768px) {
        flex-direction: column;
        align-items: center;
        text-align: center;
        gap: 15px;
    }
`;

const ProfileDetails = styled.div`
    display: flex;
    flex-direction: column;

    .name {
        font-size: 1.2rem;
        font-weight: bold;
        color: #ffffff;
        margin-bottom: 5px;
        text-align: left;

        @media (max-width: 768px) {
            text-align: center;
        }
    }

    .location {
        font-size: 0.9rem;
        color: #21ad58;
        margin-bottom: 5px;
        text-align: left;

        @media (max-width: 768px) {
            text-align: center;
        }
    }
`;


const TrophySection = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;

    img {
        width: 100px;
        margin-bottom: 10px;
    }

    .title {
        font-size: 0.9rem;
        color: #ffffff;
    }
`;

const SectionUnderWinner = ({topCoins, topRecruitment, topRank }) => {
    const generateCards = (data) =>
        data.map((user, index) => (
            <>
                <div className="name">{user?.full_name}</div>
                <div className="location">{user?.country}</div>
            </>


        ));

    return (
        <ProfileCardSection>
            <ProfileCard>
            <ProfileDetails>
            {generateCards(topRecruitment)}

            </ProfileDetails>
                <TrophySection>
                    <img src={trophie} alt="Trophy" />
                 <div className="title">Recruitment</div>
                </TrophySection>
            </ProfileCard>

            <ProfileCard >
                <ProfileDetails>
                {generateCards(topCoins)}

                </ProfileDetails>
                <TrophySection>
                    <img src={trophie} alt="Trophy" />
                    <div className="title">Coins</div>
                </TrophySection>
            </ProfileCard>


            <ProfileCard >
                <ProfileDetails>
                {generateCards(topRank)}
                </ProfileDetails>
                <TrophySection>
                    <img src={trophie} alt="Trophy" />
                    <div className="title">Rank</div>
                </TrophySection>

            </ProfileCard>
        </ProfileCardSection>
    );
};

export default SectionUnderWinner;
