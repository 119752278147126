// src/redux/actions/videoActions.js
import { SET_VIDEOS, SELECT_VIDEO, ADD_VIDEO } from "./actionTypes";

export const setVideos = (videos) => ({
    type: SET_VIDEOS,
    payload: videos,
});

export const selectVideo = (index) => ({
    type: SELECT_VIDEO,
    payload: index,
});

export const addVideo = (video) => ({
    type: ADD_VIDEO, // Action type for adding a video
    payload: video,  // Single video object as payload
});
