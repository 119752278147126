import React, { useState } from "react";
import styled from "styled-components";

// Styled components for the form and table
const Container = styled.div`
    padding: 30px;
    background-color: #000;
    color: #fff;
    min-height: 100vh;
`;

const Title = styled.h1`
    color: #21AD58;
    text-align: center;
    font-size: 24px;
    margin-bottom: 30px;
`;

const Input = styled.input`
    width: 100%;
    padding: 15px;
    margin-bottom: 15px;
    background-color: #1e1e1e;
    border: 1px solid #333;
    color: #fff;
    border-radius: 8px;
    font-size: 16px;

    &:focus {
        outline: none;
        border-color: #21AD58;
        background-color: #333;
    }
`;

const Button = styled.button`
    width: 100%;
    padding: 15px;
    background-color: #21ad58;
    color: #fff;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    font-size: 18px;
    margin-top: 20px;
    transition: background-color 0.3s;

    &:hover {
        background-color: #21AD58;
    }
`;

const Table = styled.table`
    width: 100%;
    margin-top: 30px;
    border-collapse: collapse;
`;

const TableHead = styled.thead`
    background-color: #212237;
`;

const TableRow = styled.tr`
    border-bottom: 1px solid #444;
`;

const TableHeader = styled.th`
    padding: 12px;
    text-align: left;
    color: #21AD58;
    font-size: 16px;
`;

const TableData = styled.td`
  padding: 12px;
  text-align: left;
  color: #fff;
  font-size: 14px;
`;

const NoResultsMessage = styled.p`
  text-align: center;
  font-size: 18px;
  color: #888;
  margin-top: 30px;
`;

const CompoundInterestCalculator = () => {
    const [initialDeposit, setInitialDeposit] = useState("");
    const [monthlyDeposit, setMonthlyDeposit] = useState("");
    const [monthlyWithdrawal, setMonthlyWithdrawal] = useState("");
    const [monthlyProfitability, setMonthlyProfitability] = useState("");
    const [investmentPeriod, setInvestmentPeriod] = useState("");
    const [results, setResults] = useState([]);

    const calculateCompoundInterest = () => {
        const initial = parseFloat(initialDeposit) || 0;
        const monthlyDep = parseFloat(monthlyDeposit) || 0;
        const monthlyWith = parseFloat(monthlyWithdrawal) || 0;
        const monthlyProf = parseFloat(monthlyProfitability) || 0;
        const period = parseInt(investmentPeriod) || 0;

        let currentBalance = initial;
        let totalProfit = 0;
        let totalNet = initial;
        const resultData = [];

        resultData.push({
            month: "0",
            monthlyProfitability: "----",
            profit: "----",
            deposit: initial.toFixed(2),
            withdrawal: "----",
            totalNet: totalNet.toFixed(2),
            monthlyIncrease: "----",
            balance: currentBalance.toFixed(2),
        });

        for (let i = 0; i < period; i++) {
            const monthlyProfit = currentBalance * (monthlyProf / 100);
            totalProfit += monthlyProfit;

            const monthlyIncrease = monthlyDep - monthlyWith + monthlyProfit;
            currentBalance += monthlyIncrease;
            totalNet = currentBalance;

            resultData.push({
                month: (i + 1).toString(),
                monthlyProfitability: `${monthlyProf.toFixed(2)}%`,
                profit: monthlyProfit.toFixed(2),
                deposit: monthlyDep.toFixed(2),
                withdrawal: monthlyWith.toFixed(2),
                totalNet: totalNet.toFixed(2),
                monthlyIncrease: monthlyIncrease.toFixed(2),
                balance: currentBalance.toFixed(2),
            });
        }

        setResults(resultData);
    };

    return (
        <Container>
            <Title>Compound Interest Calculator</Title>
            <Input
                type="number"
                placeholder="Initial Deposit"
                value={initialDeposit}
                onChange={(e) => setInitialDeposit(e.target.value)}
            />
            <Input
                type="number"
                placeholder="Monthly Deposit"
                value={monthlyDeposit}
                onChange={(e) => setMonthlyDeposit(e.target.value)}
            />
            <Input
                type="number"
                placeholder="Monthly Withdrawal"
                value={monthlyWithdrawal}
                onChange={(e) => setMonthlyWithdrawal(e.target.value)}
            />
            <Input
                type="number"
                placeholder="Monthly Profitability (%)"
                value={monthlyProfitability}
                onChange={(e) => setMonthlyProfitability(e.target.value)}
            />
            <Input
                type="number"
                placeholder="Investment Period (months)"
                value={investmentPeriod}
                onChange={(e) => setInvestmentPeriod(e.target.value)}
            />
            <Button onClick={calculateCompoundInterest}>Calculate</Button>

            {results.length > 0 ? (
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableHeader>Month</TableHeader>
                            <TableHeader>Monthly Profitability</TableHeader>
                            <TableHeader>Profit</TableHeader>
                            <TableHeader>Your Deposit</TableHeader>
                            <TableHeader>Monthly Withdrawal</TableHeader>
                            <TableHeader>Total Net</TableHeader>
                            <TableHeader>Monthly Increase</TableHeader>
                            <TableHeader>Account Balance</TableHeader>
                        </TableRow>
                    </TableHead>
                    <tbody>
                    {results.map((result, index) => (
                        <TableRow key={index}>
                            <TableData>{result.month}</TableData>
                            <TableData>{result.monthlyProfitability}</TableData>
                            <TableData>{result.profit}</TableData>
                            <TableData>{result.deposit}</TableData>
                            <TableData>{result.withdrawal}</TableData>
                            <TableData>{result.totalNet}</TableData>
                            <TableData>{result.monthlyIncrease}</TableData>
                            <TableData>{result.balance}</TableData>
                        </TableRow>
                    ))}
                    </tbody>
                </Table>
            ) : (
                <NoResultsMessage>No calculations yet. Please enter values and calculate.</NoResultsMessage>
            )}
        </Container>
    );
};

export default CompoundInterestCalculator;
