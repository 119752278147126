import React, {useEffect, useState} from "react";
import styled from "styled-components";
import {  FaBell } from "react-icons/fa";
import profileImage from "../../assets/images/profile.png";
import {Link} from "react-router-dom";
import {Utilites} from "../../core/Utilites";
import {Memory} from "../../core/Memory";
import RequestEngine from "../../core/RequestEngine"; // Replace with the appropriate path to your profile image

// Topbar container
const TopBarContainer = styled.div`
    width: 97%;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 20px;
    position: fixed;
    top: -1px;
    background: #0A0F0D;
    z-index: 1000;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
`;

const LeftSection = styled.div`
    display: flex;
    align-items: center;
    color: white;

    img {
        margin-right: 10px;
    }

    span {
        font-size: 16px;
        font-weight: 500;
        color: #d8e4e1;
    }
`;

const RightSection = styled.div`
    display: flex;
    align-items: center;
    gap: 20px;
`;

const IconWrapper = styled.div`
    position: relative;
    color: #d8e4e1;
    cursor: pointer;
    font-size: 18px;

    &:hover {
        color: #00ffae;
    }
`;

const NotificationDot = styled.div`
    position: absolute;
    top: -5px;
    right: -5px;
    width: 8px;
    height: 8px;
    background-color: #ff3e3e;
    border-radius: 50%;
`;

const ProfileContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 10px;
    color: #d8e4e1;

    img {
        width: 40px;
        height: 40px;
        border-radius: 50%;
    }

    div {
        display: flex;
        flex-direction: column;
        text-align: left;

        span {
            &:first-child {
                font-size: 14px;
                font-weight: 500;
            }

            &:last-child {
                font-size: 12px;
                color: #8e9a95;
            }
        }
    }
`;

const ModalOverlay = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
`;

const ModalContent = styled.div`
    background-color: white;
    padding: 30px;
    border-radius: 10px;
    text-align: center;
    width: 400px;

    h2 {
        margin-bottom: 15px;
        color: #000;
    }

    p {
        font-size: 16px;
        color: #000;
    }

    button {
        margin-top: 20px;
        padding: 10px 20px;
        background-color: #21ad58;
        color: white;
        border: none;
        border-radius: 5px;
        cursor: pointer;

        &:hover {
            background-color: #33ff99;
        }
    }
`;


const TopBar = () => {

    const [isSubMenuOpen, setSubMenuOpen] = useState(false);
    const [username, setUsername] = useState("");
    const [myreferral, setMyreferral] = useState("");
    const [referral, setReferral] = useState("");

    const [ismember, setIsmember] = useState(false);
    const [rank, setRank] = useState(""); // Add rank state
    const [membershipExpiry, setMembershipExpiry] = useState(""); // Add membership expiry state

    const handleShare = () => {
        const shareLink = `https://multyncome.com/signup?ref=${myreferral}`;
        navigator.clipboard.writeText(shareLink);
        Utilites.showSucessMessage("Link Copied! : " + shareLink);
    };
    const [isNotificationOpen, setNotificationOpen] = useState(false);
    const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);
    const [notifications, setNotifications] = useState([]);
    const [notificationCount, setNotificationCount] = useState(0);
    const [isModalOpen, setModalOpen] = useState(false); // Modal visibility state

    useEffect(() => {
        const fetchData = async () => {
            const user = JSON.parse(Memory.getItem("userinfo"));
            setUsername(user.username);
            setMyreferral(user.myreferral);
            setIsmember(user.ismember);
            setReferral(user.referral);
        };

        const fetchNotifications = async () => {
            let engine = new RequestEngine();
            try {
                const response = await engine.getItem("user/notification/list");
                if (response && response.status === 200 && response.data) {
                    setNotifications(response.data.data);
                    setNotificationCount(response.data.data.length);
                }
            } catch (error) {
                console.error("Error fetching notifications:", error);
            }
        };

        fetchData();
        Utilites.setTimeout(
            ()=>{
                fetchNotifications()
            },1500
        )
        Utilites.setTimeout(
            ()=>{
                handleMemberClick(null,false)
            },1000
        )
    }, []);



    const handleMemberClick = async (e,showModal=true) => {
        e?.stopPropagation();
        let engine = new RequestEngine();
        try {
            const response = await engine.getItem("user/me");
            if (response && response.status === 200 && response.data.success) {
                const { rank, membershipexpiry } = response.data.data.userinfo;
                Memory.setItem('userinfo', JSON.stringify(response.data.data.userinfo));

                setRank(rank?.title || "Unranked");
                setMembershipExpiry(Utilites.renderDate(membershipexpiry));
                if(showModal){
                    setModalOpen(true); // Open the modal when MEMBER is clicked
                }

            }else{
                Utilites.showErrorMessage("error please try again later")
            }
        } catch (error) {
            console.error("Error fetching membership details:", error);
        }
    };

    const closeModal = () => {
        setModalOpen(false); // Close the modal
    };

    return (
        <TopBarContainer >
            <LeftSection>
            </LeftSection>
            <RightSection>
                {!ismember && <Link to="/subscribe" activeClassName="active" style={{color:"red"}}>Subscribe!</Link>}
                <Link to="/activity">
                    <IconWrapper>
                        <FaBell />
                        <NotificationDot />
                    </IconWrapper>
                </Link>
                <ProfileContainer>
                    <img src={profileImage} alt="Profile" />
                    <div>
                        <span>{username} </span>
                        {ismember && (
                            <div
                                onClick={(e) => handleMemberClick(e)}
                            >
                                <div><small
                                    style={{color:"#21AD58", cursor: "pointer",fontSize:13}}

                                >
                                    MEMBER
                                </small>
                                </div>
                                <div> <span style={{fontSize: 12,color: "red"}}> {membershipExpiry}</span></div>
                            </div>
                        )}
                    </div>
                </ProfileContainer>
            </RightSection>

            {isModalOpen && (
                <ModalOverlay onClick={closeModal}>
                    <ModalContent onClick={(e) => e.stopPropagation()}>
                        <h2>Membership Details</h2>
                        <p>Welcome {username} </p>
                        <p><strong>Rank:</strong> {rank}</p>
                        <p><strong>Membership Expiry:</strong> {membershipExpiry}</p>
                        {ismember && <p><strong>REFERAL CODE:</strong> <a href={"#"} style={{cursor: "pointer"}}
                                                                          onClick={handleShare}> {myreferral}</a></p>}
                        <button onClick={closeModal}>Close</button>
                    </ModalContent>
                </ModalOverlay>
            )}
        </TopBarContainer>
    );
};

export default TopBar;
