import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { FaEye, FaEyeSlash } from 'react-icons/fa';  // Eye icons for password visibility
import PhoneInput from 'react-phone-input-2';
import  {  isValidPhoneNumber } from 'react-phone-number-input'
import 'react-phone-input-2/lib/style.css';  // Import the CSS for the phone input
import RequestEngine from "../../core/RequestEngine";
import { Memory } from "../../core/Memory";
import { Utilites } from "../../core/Utilites";
import logo from "../../assets/images/logo.svg";
import Constants from "../../core/Constants";

// Container for the entire signup screen
const SignupContainer = styled.div`
    display: flex;
    height: 100vh;

    @media (max-width: 768px) {
        flex-direction: column;
        height: auto;
    }
`;

const SignupForm = styled.div`
    width: 100%;
    max-width: 500px;
    background-color: #0F211C;
    padding: 40px;
    border-radius: 10px;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.3);

    @media (max-width: 768px) {
        padding: 20px;
        max-width: 90%;
    }
`;

const Title = styled.h2`
    margin-bottom: 20px;
    color: #21AD58;
    font-size: 24px;
    font-weight: bold;
    text-align: center;
`;

const InputField = styled.input`
    width: 94%;
    padding: 12px 15px;
    margin-bottom: 15px;
    background-color: #162421;
    border: 1px solid #21AD58;
    border-radius: 8px;
    color: #FFFFFF;
    font-size: 14px;
    outline: none;

    &:focus {
        border-color: #47FFA6;
    }
`;

const SignupButton = styled.button`
    width: 100%;
    padding: 12px;
    background-color: #21AD58;
    color: #0A0F0D;
    border: none;
    border-radius: 8px;
    font-size: 16px;
    font-weight: bold;
    cursor: pointer;
    margin: 5px;
    transition: background-color 0.3s ease;

    &:hover {
        background-color: #2DD784;
    }

    &:disabled {
        background-color: #9DD1B3;
        cursor: not-allowed;
    }
`;

const FooterText = styled.p`
    margin-top: 20px;
    text-align: center;
    font-size: 14px;
    color: #FFFFFF;

    a {
        color: #21AD58;
        text-decoration: none;
        &:hover {
            text-decoration: underline;
        }
    }
`;

const ErrorMessage = styled.p`
    color: #FF6B6B;
    font-size: 12px;
    margin-top: -10px;
    margin-bottom: 10px;
`;

const CountrySelect = styled.select`
    width: 100%;
    padding: 15px;
    margin-bottom: 10px;
    border: 1px solid #ddd;
    background-color: #162421;
    border-radius: 5px;
    color: #FFFFFF;
    font-size: 16px;
`;






// Right section with the signup form
const RightSection = styled.div`
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 50px;

    @media (max-width: 768px) {
        padding: 20px;
    }
`;


const Row = styled.div`
    display: flex;
    justify-content: space-between;
    gap: 10px;
`;

const ButtonRow = styled.div`
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
`;

const ButtonLoader = styled.div`
    border: 3px solid rgba(255, 255, 255, 0.3);
    border-top: 3px solid #fff;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    animation: spin 0.8s linear infinite;

    @keyframes spin {
        0% { transform: rotate(0deg); }
        100% { transform: rotate(360deg); }
    }
`;


const TermsContainer = styled.div`
    display: flex;
    align-items: center;
    margin-top: 10px;
`;

const Checkbox = styled.input`
    margin-right: 10px;
`;

const TermsLink = styled.span`
    color: #4caf50;
    cursor: pointer;
    text-decoration: underline;
    &:hover {
        color: #21AD58;
    }
`;

// Modal Styles
const Modal = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
`;

const ModalContent = styled.div`
    background-color: black;
    padding: 30px;
    border-radius: 10px;
    max-width: 400px;
    max-height: 70vh;
    overflow-y: auto;
    text-align: center;
`;

const ModalButton = styled.button`
    background-color: #14562C;
    color: white;
    padding: 10px 20px;
    margin: 5px;
    border: none;
    border-radius: 5px;
    font-size: 13px;
    cursor: pointer;
    margin-top: 20px;

    &:hover {
        background-color: #45a049;
    }
`;

// Styled button with loading control


// Eye toggle button for password fields
const EyeToggleButton = styled.button`
    position: absolute;
    right: 10px;
    top: 12px;
    background: none;
    border: none;
    cursor: pointer;
    font-size: 18px;
    color: #000000;

    &:focus {
        outline: none;
    }
`;

// Modal Styles



const SignupScreen = () => {
    const [step, setStep] = useState(1);
    const [showReferralDialog, setShowReferralDialog] = useState(false);
    const [phone, setPhone] = useState('');  // Updated state to store phone input with country code
    const [phoneValid, setPhoneValid] = useState(true);  // Validation state
    const navigate = useNavigate();
    const location = useLocation();

    const [loadingNext, setLoadingNext] = useState(false);
    const [loadingSubmit, setLoadingSubmit] = useState(false);
    // Form field values
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [username, setUsername] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [country, setCountry] = useState('');
    const [nationalId, setNationalId] = useState('');
    const [referral, setReferral] = useState('');

    const [showTermsModal, setShowTermsModal] = useState(false);  // For terms modal
    const [agreeTerms, setAgreeTerms] = useState(false);  // Checkbox state for terms agreement


    const [errors, setErrors] = useState({});
    const [showPassword, setShowPassword] = useState(false); // Toggle for password visibility
    const [showConfirmPassword, setShowConfirmPassword] = useState(false); // Toggle for confirm password

    useEffect(() => {
        const params = new URLSearchParams(location.search);
        const refCode = params.get('ref');
        if (refCode) {
            setReferral(refCode);
        }
    }, [location]);

    // Real-time email validation
    const validateEmail = (value) => {
        const pattern = /\S+@\S+\.\S+/;
        return pattern.test(value);
    };

    const handleEmailChange = (value) => {
        setEmail(value);
        if (!validateEmail(value)) {
            setErrors((prev) => ({ ...prev, email: 'Invalid email format' }));
        } else {
            setErrors((prev) => ({ ...prev, email: null }));
        }
    };

    const validateStep1 = async () => {
        let errors = {};

        if (!email.trim()) errors.email = 'Email is required';
        else if (!validateEmail(email)) errors.email = 'Email is invalid';

        if (!firstName.trim()) errors.firstName = 'First name is required';
        if (!lastName.trim()) errors.lastName = 'Last name is required';
        if (!username.trim()) errors.username = 'Username is required';

        setErrors(errors);

        if (Object.keys(errors).length === 0) {
            try {
                let engine = new RequestEngine();
                const data = { email, username };
                const response = await engine.postItem("user/validate", data);
                if (response.data.success) {
                    return true; // Validated successfully
                } else {
                    Utilites.showErrorMessage(response.data.message);
                    return false;
                }
            } catch (error) {
                console.error("Validation error:", error);
                return false;
            }
        }
        return false;
    };

    const validatePassword = (password) => {
        const regex = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[!@#\$&*~?_]).{8,}$/;
        return regex.test(password);
    };
    const validateStep2 = () => {
        let errors = {};
        if (!password) errors.password = 'Password is required';
        else if (!validatePassword(password)) errors.password = 'Password must be at least 8 characters long and include an uppercase letter, lowercase letter, number, and special character.';

        if (!confirmPassword) errors.confirmPassword = 'Confirm password is required';
        else if (password !== confirmPassword) errors.confirmPassword = 'Passwords do not match';

        setErrors(errors);
        return Object.keys(errors).length === 0;
    };

    const validateStep3 = () => {
        let errors = {};
        if (!country.trim()) errors.country = 'Country is required';
        if (!nationalId.trim()) errors.nationalId = 'National ID is required';
        if (!phone.trim() || !phoneValid) errors.phone = 'Valid phone number is required';  // Check if phone is valid
        setErrors(errors);
        return Object.keys(errors).length === 0;
    };

    const handleNextStep = async () => {
        setLoadingNext(true);
        if (step === 1 && await validateStep1()) setStep(2);
        if (step === 2 && validateStep2()) setStep(3);
        setLoadingNext(false);
    };

    const handlePrevStep = () => {
        if (step > 1) setStep(step - 1);
    };

    const handleReferralCheck = () => {
        if (!referral) {
            setShowReferralDialog(true);
        } else {
            handleSubmit();
        }
    };

    const handleSubmit = async () => {
        if (!agreeTerms) {
            setErrors((prev) => ({ ...prev, terms: 'Please agree to the terms and services' }));
            return;
        }
        setLoadingSubmit(true);

        setShowReferralDialog(false);
        if (validateStep3()) {
            const formData = {
                first_name: firstName,
                last_name: lastName,
                username,
                email,
                password,
                country,
                nationalId,
                phone_number: phone,  // Pass the phone number
                referral
            };

            try {
                let engine = new RequestEngine();
                const response = await engine.postItem("user/save", formData);
                if (response.data.success) {
                    const token = response.data.data.token;
                    const userid = response.data.data.userid;
                    const userinfo = response.data.data.user;

                    Memory.setItem('token', token);
                    Memory.setItem('userid', userid);
                    Memory.setItem('userinfo', JSON.stringify(userinfo));
                    Memory.setItem('isloggedin', true);

                    Utilites.showSucessMessage(`Welcome ${userinfo.username}!`);
                    navigate('/dashboard');
                } else {
                    Utilites.showErrorMessage(response.data.message);
                }
            } catch (error) {
                console.error("Error during submission:", error);
            }
        }
        setLoadingSubmit(false);
    };

    return (
        <SignupContainer>
            {showReferralDialog && (
                <Modal>
                    <ModalContent>
                        <h3>Referral Code</h3>
                        <p>
                            It's recommended to add a referral code to benefit from additional rewards. Would you like to proceed without it?
                        </p>
                        <ModalButton onClick={() => setShowReferralDialog(false)}>Add Referral Code</ModalButton>
                        <ModalButton onClick={handleSubmit}>Proceed Without Referral</ModalButton>
                    </ModalContent>
                </Modal>
            )}


            {showTermsModal && (
                <Modal>
                    <ModalContent>
                        <h3>Terms and Services</h3>
                        <p style={{textAlign:"left"}}>
                            Last Updated: [10 October 2024]

                            Welcome to Multyncome! By registering for and using our platform, you agree to the following terms and conditions. These terms are legally binding, and you must carefully review them before proceeding.

                            1.⁠ ⁠Acceptance of Terms

                            By creating an account with Multyncome, you confirm that you are at least 18 years old and legally capable of entering into binding agreements. Use of the platform implies acceptance of these terms. If you do not agree with any part of these terms, please refrain from using our services.

                            2.⁠ ⁠Services Overview

                            Multyncome provides:

                            •	Access to automated trading bots (manual trading is not involved).
                            •	Educational resources, including courses on social media strategies, content creation, and online income generation.
                            •	Connectivity with licensed financial brokers for investment activities.
                            •	An affiliate and referral system offering rewards, incentives, and progressive rank achievements.
                            •	A comprehensive platform for team-building and community interaction.

                            Multyncome retains the right to adjust or discontinue services without prior notice.

                            3.⁠ ⁠User Registration and Responsibilities

                            •	You agree to provide accurate, current, and complete information during registration.
                            •	You are responsible for maintaining the confidentiality of your login details.
                            •	Unauthorized use or suspicious activity must be reported to Multyncome promptly.
                            •	The platform must only be used for lawful purposes and in accordance with the terms outlined herein.

                            4.⁠ ⁠Affiliate and Referral Program

                            Multyncome offers a performance-based affiliate program:

                            •	Users earn rewards through referrals and successful conversions within the platform.
                            •	Ranking within the system unlocks additional rewards and benefits.
                            •	The program’s structure, rewards, and terms are subject to modification by Multyncome.

                            5.⁠ ⁠Investment and Risk Acknowledgment

                            •	All investment operations are conducted through licensed financial brokers connected with the platform.
                            •	Trading bots are optimized for consistent performance, but no financial gain is guaranteed.
                            •	Users are fully responsible for their investment decisions and acknowledge that all investments carry inherent risks.

                            6.⁠ ⁠Membership Benefits

                            Members enjoy additional benefits, such as:

                            •	Discounted fees on profits earned through investments.
                            •	Enhanced referral commissions and reward structures.
                            •	Special access to exclusive content, tools, and community features.

                            The details of membership levels and associated privileges are available within the platform.

                            7.⁠ ⁠Prohibited Activities

                            Users agree to refrain from:

                            •	Engaging in any unlawful activities or activities that violate local or international laws.
                            •	Providing misleading or false information within the platform.
                            •	Manipulating the affiliate system or engaging in fraudulent activities.
                            •	Copying or distributing platform content without prior authorization from Multyncome.

                            8.⁠ ⁠Termination of Account

                            •	Multyncome reserves the right to suspend or terminate any account that violates these terms.
                            •	Users may deactivate their accounts at any time through the appropriate channels within the platform.
                            •	Upon termination, access to the platform and its features will be revoked, and any pending commissions will be processed according to the applicable policies.

                            9.⁠ ⁠Data Protection and Privacy

                            Your use of Multyncome is subject to our Privacy Policy, which explains how we collect, store, and process your personal data. By registering, you consent to the practices described in the Privacy Policy.

                            10.⁠ ⁠Disclaimer and Limitation of Liability

                            •	Multyncome provides no guarantees regarding financial outcomes or uninterrupted service.
                            •	Users understand that the use of trading bots and other services is at their own risk.
                            •	Multyncome is not liable for any financial losses, damages, or disputes arising from the use of the platform.

                            11.⁠ ⁠Modification of Terms

                            These terms and conditions are subject to change at the sole discretion of Multyncome. Users will be notified of any significant changes via email or platform notifications. Continued use of the platform after any updates constitutes acceptance of the revised terms.

                            12.⁠ ⁠Governing Law and Jurisdiction

                            These terms are governed by the laws of the country where Multyncome is registered. Any disputes arising from the use of the platform will be subject to the exclusive jurisdiction of the courts in the country of registration.

                            Contact Us

                            For any questions or concerns about these terms and conditions, please contact us at support@multyncome.com.
                        </p>
                        {/* Add actual terms and services content here */}
                        <ModalButton onClick={() => setShowTermsModal(false)}>Close</ModalButton>
                    </ModalContent>
                </Modal>
            )}
            <RightSection>
                <SignupForm>
                    <Title>Create Your Account</Title>
                    <p>Fill in the details to get started</p>

                    {step === 1 && (
                        <>
                            <InputField
                                type="text"
                                placeholder="Enter your first name"
                                value={firstName}
                                onChange={(e) => setFirstName(e.target.value)}
                            />
                            {errors.firstName && <ErrorMessage>{errors.firstName}</ErrorMessage>}
                            <InputField
                                type="text"
                                placeholder="Enter your last name"
                                value={lastName}
                                onChange={(e) => setLastName(e.target.value)}
                            />
                            {errors.lastName && <ErrorMessage>{errors.lastName}</ErrorMessage>}
                            <InputField
                                type="text"
                                placeholder="Enter your username"
                                value={username}
                                onChange={(e) => setUsername(e.target.value)}
                            />
                            {errors.username && <ErrorMessage>{errors.username}</ErrorMessage>}
                            <InputField
                                type="text"
                                placeholder="Enter your Email"
                                value={email}
                                onChange={(e) => handleEmailChange(e.target.value)}
                            />
                            {errors.email && <ErrorMessage>{errors.email}</ErrorMessage>}
                            <ButtonRow>
                                <SignupButton onClick={handleNextStep} disabled={loadingNext}>
                                    {loadingNext ? <ButtonLoader /> : 'Next'}
                                </SignupButton>
                            </ButtonRow>
                        </>
                    )}

                    {step === 2 && (
                        <>
                            <Row>
                                <div style={{ position: 'relative', width: '100%' }}>
                                    <InputField
                                        type={showPassword ? 'text' : 'password'}
                                        placeholder="Enter your password"
                                        value={password}
                                        onChange={(e) => setPassword(e.target.value)}
                                    />
                                    <EyeToggleButton onClick={() => setShowPassword(!showPassword)}>
                                        {showPassword ? <FaEyeSlash /> : <FaEye />}
                                    </EyeToggleButton>
                                </div>
                            </Row>
                            <Row>
                                <div style={{ position: 'relative', width: '100%' }}>
                                    <InputField
                                        type={showConfirmPassword ? 'text' : 'password'}
                                        placeholder="Confirm your password"
                                        value={confirmPassword}
                                        onChange={(e) => setConfirmPassword(e.target.value)}
                                    />
                                    <EyeToggleButton onClick={() => setShowConfirmPassword(!showConfirmPassword)}>
                                        {showConfirmPassword ? <FaEyeSlash /> : <FaEye />}
                                    </EyeToggleButton>
                                </div>
                            </Row>
                            {errors.password && <ErrorMessage>{errors.password}</ErrorMessage>}
                            {errors.confirmPassword && <ErrorMessage>{errors.confirmPassword}</ErrorMessage>}
                            <ButtonRow>
                                <SignupButton onClick={handlePrevStep}>Back</SignupButton>
                                <SignupButton onClick={handleNextStep} disabled={loadingNext}>
                                    {loadingNext ? <ButtonLoader /> : 'Next'}
                                </SignupButton>
                            </ButtonRow>
                        </>
                    )}

                    {step === 3 && (
                        <>
                            <CountrySelect
                                value={country}
                                onChange={(e) => setCountry(e.target.value)}
                            >
                                <option value="">Select your country</option>
                                {Constants.countries.map((country, index) => (
                                    <option key={index} value={country}>
                                        {country}
                                    </option>
                                ))}
                            </CountrySelect>
                            {errors.country && <ErrorMessage>{errors.country}</ErrorMessage>}
                            <InputField
                                type="text"
                                placeholder="Enter your national ID"
                                value={nationalId}
                                onChange={(e) => setNationalId(e.target.value)}
                            />
                            {errors.nationalId && <ErrorMessage>{errors.nationalId}</ErrorMessage>}

                            {/* Phone Input with Country Code */}
                            <PhoneInput
                                country={'lb'}
                                value={phone}
                                onChange={(phone, country) => {
                                    setPhone(phone);
                                    setPhoneValid(isValidPhoneNumber("+"+phone));
                                }}
                                enableSearch={true}
                                containerStyle={{ marginBottom: '10px' }}
                                inputStyle={{ width: '100%',background:"#162421;", padding: '20px', paddingLeft: "52px", border: '1px solid #ddd', borderRadius: '5px', fontSize: '16px' }}
                            />
                            {errors.phone && <ErrorMessage>{errors.phone}</ErrorMessage>}
                            <InputField
                                type="text"
                                placeholder="Enter referral code (optional)"
                                value={referral}
                                onChange={(e) => setReferral(e.target.value)}
                            />
                            <TermsContainer>
                                <Checkbox type="checkbox" checked={agreeTerms} onChange={() => setAgreeTerms(!agreeTerms)} />
                                <TermsLink onClick={() => setShowTermsModal(true)}>I agree to the terms and services</TermsLink>
                            </TermsContainer>
                            {errors.terms && <ErrorMessage>{errors.terms}</ErrorMessage>}

                            <ButtonRow>
                                <SignupButton onClick={handlePrevStep}>Back</SignupButton>
                                <SignupButton onClick={handleReferralCheck} disabled={loadingSubmit}>
                                    {loadingSubmit ? <ButtonLoader /> : 'Submit'}
                                </SignupButton>
                            </ButtonRow>
                        </>
                    )}

                    <FooterText>
                        Already have an account? <Link to="/login">Login</Link>
                    </FooterText>
                </SignupForm>
            </RightSection>
        </SignupContainer>
    );
};

export default SignupScreen;
