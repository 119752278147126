import React, { useEffect, useState } from "react";
import styled from "styled-components";
import top1 from "../../../assets/images/top5.png";
import top2 from "../../../assets/images/top8.svg";

// Styled Components
const BoxContent = styled.div`
    padding-top: 100px;
    opacity: ${(props) => (props.isVisible ? 1 : 0)};
    transition: opacity 1s ease-in-out;
`;

const Content = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 50px;
    padding: 0 20px;
    width: 80%;
    margin: 0 auto;
    padding-top: 100px;
    padding-bottom: 50px;
    gap: 20px;
    opacity: ${(props) => (props.isVisible ? 1 : 0)};
    transform: ${(props) => (props.isVisible ? "translateX(0)" : "translateX(50px)")};
    transition: transform 1.5s ease-in-out, opacity 1.5s ease-in-out;

    @media (max-width: 768px) {
        flex-direction: column-reverse;
        width: 90%;
        padding-top: 50px;
        gap: 30px;
    }
`;

const Section = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;

    @media (max-width: 768px) {
        width: 100%;
    }
`;

const TextSection = styled(Section)`
    align-items: flex-start;
    text-align: left;

    @media (max-width: 768px) {
        align-items: center;
        text-align: center;
    }
`;

const Title = styled.h1`
    color: #fff;
    font-size: 48px;
    line-height: 1.2;
    margin-bottom: 20px;
    opacity: ${(props) => (props.isVisible ? 1 : 0)};
    transition: opacity 1s ease-in-out;

    @media (max-width: 768px) {
        font-size: 36px;
    }

    @media (max-width: 480px) {
        font-size: 28px;
    }
`;

const Subtitle = styled.p`
    font-size: 16px;
    margin-bottom: 10px;
    font-weight: 400;
    color: #b3b3b3;
    line-height: 25.2px;
    opacity: ${(props) => (props.isVisible ? 1 : 0)};
    transition: opacity 1s ease-in-out;

    @media (max-width: 768px) {
        font-size: 14px;
    }

    @media (max-width: 480px) {
        font-size: 12px;
    }
`;

const KeyPointsList = styled.ul`
    font-size: 16px;
    font-weight: 300;
    list-style-type: disc;
    color: #b3b3b3;
    opacity: ${(props) => (props.isVisible ? 1 : 0)};
    transition: opacity 1s ease-in-out;

    @media (max-width: 768px) {
        font-size: 14px;
    }

    @media (max-width: 480px) {
        font-size: 12px;
    }
`;

const KeyPoint = styled.li`
    margin-bottom: 10px;
`;

const ImageSection = styled(Section)`
    img {
        max-width: 100%;
        height: auto;
        width: 500px;
        opacity: ${(props) => (props.isVisible ? 1 : 0)};
        transition: opacity 1s ease-in-out;

        @media (max-width: 768px) {
            width: 350px;
        }

        @media (max-width: 480px) {
            width: 250px;
        }
    }
`;

const SecondImageSection = styled(Section)`
    img {
        height: auto;
        width: 100%;
        opacity: ${(props) => (props.isVisible ? 1 : 0)};
        transition: opacity 1s ease-in-out;
    }
`;

const Section5 = () => {
    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        const observer = new IntersectionObserver(
            (entries) => {
                const entry = entries[0];
                if (entry.isIntersecting) {
                    setIsVisible(true); // Set to true when the section is in view
                }
            },
            {
                threshold: 0.5, // Trigger animation when 50% of the section is in view
            }
        );

        const section = document.querySelector("#section5");
        if (section) {
            observer.observe(section);
        }

        return () => {
            if (section) {
                observer.unobserve(section);
            }
        };
    }, []);

    return (
        <BoxContent id="section5" isVisible={isVisible}>
            <SecondImageSection isVisible={isVisible}>
                <img src={top2} alt="Second Image" />
            </SecondImageSection>

            <Content isVisible={isVisible}>
                {/* First Image Section */}
                <ImageSection isVisible={isVisible}>
                    <img src={top1} alt="Multyncome" />
                </ImageSection>

                {/* Text Section */}
                <TextSection isVisible={isVisible}>
                    <Title isVisible={isVisible}>Partnership Opportunity and Affiliate Program</Title>
                    <Subtitle isVisible={isVisible}>
                        • Title: “Partner with Multyncome and Start Earning Extra Income”.
                    </Subtitle>
                    <Subtitle isVisible={isVisible}>
                        • Description: “Join our partnership program, earn commissions by
                        referring friends and family, and learn essential skills for
                        generating online income.”
                    </Subtitle>
                    <Subtitle isVisible={isVisible}>KeyPoint :</Subtitle>
                    <KeyPointsList isVisible={isVisible}>
                        <KeyPoint>Profitable referral programs to maximize your earnings.</KeyPoint>
                        <KeyPoint>Learn the skills you need to build a sustainable online income.</KeyPoint>
                        <KeyPoint>Comprehensive support to help you grow.</KeyPoint>
                    </KeyPointsList>
                </TextSection>
            </Content>
        </BoxContent>
    );
};

export default Section5;
